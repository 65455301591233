import { useEffect, useState } from 'react';

import { FilterPopupInitialValuesType } from '../types';
import { ANY } from '../constants/building/availableCondos';

type setValueType = (value: string) => void;
export type valuesType = { min: string; max: string };

type UseFilterPopupArguments = {
  onChange?: (values: valuesType) => void;
  initialValues: FilterPopupInitialValuesType;
  isReloadOnBlur?: boolean;
};

function useFilterPopup({
  onChange,
  initialValues,
  isReloadOnBlur,
}: UseFilterPopupArguments): {
  setMaxValue: setValueType;
  setMinValue: setValueType;
  values: valuesType;
  filterMaxValues: (maxValues: string[]) => string[];
  filterMinValues: (maxValues: string[]) => string[];
} {
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    if (onChange && !isReloadOnBlur) {
      JSON.stringify(values) !== JSON.stringify(initialValues) &&
        onChange(values);
    }
  }, [values]);

  function filterMaxValues(maxValues: string[]): string[] {
    return maxValues.filter((value) => {
      const maxValue = Number(value);
      const minValue = Number(values.min);

      return !isNaN(maxValue) && !isNaN(minValue)
        ? maxValue >= minValue
        : value;
    });
  }

  function filterMinValues(minValues: string[]): string[] {
    return minValues.filter((value) => {
      const minValue = Number(value);
      const maxValue = Number(values.max);

      return !isNaN(maxValue) && !isNaN(minValue)
        ? maxValue >= minValue
        : value;
    });
  }

  function setMinValue(value: string) {
    const minValue = value || ANY;
    let maxValue = values.max;

    if (Number(minValue) && Number(minValue) > Number(maxValue)) {
      maxValue = ANY;
    }
    setValues({ min: minValue, max: maxValue });
  }

  function setMaxValue(value: string) {
    const maxValue = value || ANY;

    setValues({ ...values, max: maxValue });
  }

  return { setMaxValue, setMinValue, values, filterMaxValues, filterMinValues };
}

export default useFilterPopup;
