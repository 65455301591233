import React, { useContext, useMemo } from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { NO_DATA_PLACEHOLDER } from 'constants/labels';
import {
  // getAssociationFee,
  priceFormatter,
  priceFormatterWithCent,
} from 'src/utils/utils';
// import { daysDiff } from 'lib/dateDiff';
import { isListingOffMarket } from 'lib/checkListingStatus';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';

import floorplan from '../../public/images/floorplan.svg';
import { KeyValueList } from '../KeyValueList';
import FlagLabel from '../FlagLabel';
import TooltipInfo from '../UnitsFilters/components/moreFiltersFields/TooltipInfo';

interface KeyDetailsProps {
  floorplanCoordinates?: number;
}

const KeyDetails: React.FC<KeyDetailsProps> = ({ floorplanCoordinates }) => {
  const { building, listing, neighborhood, city } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;
  const {
    mlsListingId,
    price,
    sqft,
    propertyTypeCode,
    statusCode,
    // createdAt,
    amenity,
    hoaFees,
    maintenanceFee,
    // associationFeePaidPer,
  } = listing;
  const daysOnMarket = Math.floor(
    (+new Date() - +new Date(listing.createdAt)) / 24 / 3600 / 1000
  );
  const buildingAssociationFee =
    building?.buildingStats?.averageAssociationFeeSqftPrice || 0;
  const petsDescription: string = useMemo(() => {
    const petsAmenity = amenity?.pets;
    // const buildingPetsAmenity = building?.amenity?.pets;
    const description: string[] = [];
    if (propertyTypeCode === 'R') {
      if (petsAmenity.allowed) {
        description.push('Pets OK for Renters');
        description.push(
          petsAmenity.large ? 'Large Pets allowed' : 'Small Pets allowed'
        );
      } else return 'No Pets for Renters';
    } else {
      if (petsAmenity.allowed) {
        description.push('Pets OK for Owners');
        description.push(
          petsAmenity.large ? 'Large Pets Allowed' : 'Small Pets Allowed'
        );
      } else return 'No Pets for Owners';
    }

    return description.join(', ');
  }, [statusCode, amenity]);
  const detailsLeftCol = [
    {
      key: 'City / Neighborhood',
      value: (
        <>
          {city.name && <a>{city.name} / </a>}
          <Link
            href={`/${neighborhood.slug}`}
            passHref
            className="underline text-gold-dark hover:text-gold-hover"
          >
            {neighborhood.name}
          </Link>
        </>
      ),
    },
    {
      key: 'Building name',
      value: (
        <Link
          href={`/${neighborhood.slug}/${building.slug}`}
          passHref
          className="underline text-gold-dark hover:text-gold-hover"
        >
          {building.name}
        </Link>
      ),
    },
    {
      key: 'MLS #',
      value: mlsListingId,
    },

    ...(propertyTypeCode !== 'R'
      ? [
          {
            key: 'Price / Sq Ft',
            value:
              price / sqft !== Infinity &&
              price / sqft > 0 &&
              !isListingOffMarket(statusCode)
                ? priceFormatter(price / sqft)
                : `$${NO_DATA_PLACEHOLDER}`,
          },
        ]
      : [
          {
            key: 'Price / Sq Ft',
            value:
              price / sqft !== Infinity &&
              price / sqft > 0 &&
              !isListingOffMarket(statusCode)
                ? priceFormatterWithCent(price / sqft)
                : `$${NO_DATA_PLACEHOLDER}`,
          },
        ]),
    ...(+listing.taxes
      ? [
          {
            key: 'Taxes',
            value: priceFormatter(+listing.taxes),
          },
        ]
      : []),
    ...(propertyTypeCode !== 'R'
      ? [
        {
          key: 'Association Fee',
          value:
            +hoaFees || +maintenanceFee
            ? `$${+hoaFees || +maintenanceFee}/month`
            : +buildingAssociationFee && +sqft
            ? (
              <>
                ${Math.round(buildingAssociationFee * sqft)}/month
                <TooltipInfo
                  text="There was no association fee associated to this property
                 in the MLS/IDX. As part of Condo BlackBook's goal in helping
                 you make informed decisions, we have provided you with a
                 likely estimate of the association for this property, which
                 we believe to be reliable but cannot guarantee accuracy.
                 Please verify with the condo association or one of our
                 real estate professionals prior to purchase."
                />
              </>
            )
            : NO_DATA_PLACEHOLDER,
        },
        ]
      : []),
  ];

  const detailsRightCol = [
    {
      key: 'Tax amount',
      value:
        +listing.taxAmount > 0
          ? `${priceFormatter(+listing.taxAmount)}/y`
          : NO_DATA_PLACEHOLDER,
    },
    ...(statusCode === 'A' || statusCode === 'AC'
      ? [
          {
            key: 'Days on market',
            value: +daysOnMarket,
          },
        ]
      : []),
    {
      key: 'Walk Score ®',
      value: building?.walkscore?.walk ? (
        <a
          target="_blank"
          rel="nofollow noopener noreferrer"
          className="text-gold-dark hover:text-gold-hover"
          href="https://www.walkscore.com/how-it-works/"
        >
          {building?.walkscore?.walk}
          &nbsp; ({building?.walkscore?.walkDescription})
        </a>
      ) : (
        NO_DATA_PLACEHOLDER
      ),
    },
    ...(petsDescription
      ? [
          {
            key: 'Pets',
            value: petsDescription,
          },
        ]
      : []),
  ];

  return (
    <section className="container mt-[7.5px] md:pt-[8px] lg:py-2 md:mt-0 lg:mt-[47px] lg:pb-1 lg:pt-0 text-m">
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="relative flex flex-col justify-center mt-px w-max">
          <FlagLabel name="Key features" />
        </div>
        <button className="flex items-center w-[174px] h-8 pl-[16px] pr-[4px] mt-6 space-x-2 uppercase bg-white border-2 outline-none md:w-auto border-gold-dark md:mt-0 md:ml-10">
          <div className="mt-[4px]">
            <Image src={floorplan} alt="floorplan" width={15} height={15} />
          </div>
          <a
            onClick={() => {
              scrollTo({
                top: floorplanCoordinates,
                behavior: 'smooth',
              });
            }}
          >
            <div className="px-[4px] whitespace-nowrap text-14-18-0.3">
              View floorplan
            </div>
          </a>
        </button>
      </div>
      <div className="grid mt-[28px] mb-[11px] md:mb-[45px] md:mt-10 lg:grid-cols-2 gap-x-16">
        <KeyValueList
          rowsClassName="lg:last:border-b-0 md:!grid-cols-5"
          rows={detailsLeftCol}
          colLeftClassName="font-sohneKraftig py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3 md:!col-span-2"
          colRightClassName="flex items-center py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3 md:!col-span-3"
        />
        <KeyValueList
          rows={detailsRightCol}
          rowsClassName="md:!grid-cols-5 last:border-b-0"
          colLeftClassName="font-sohneKraftig py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3 md:!col-span-2"
          colRightClassName="py-[6px] md:py-[4px] text-14-22-0.3 md:text-16-26-0.3 md:!col-span-3"
        />
      </div>
    </section>
  );
};

export default KeyDetails;
