import React, { useCallback, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';

import {
  isNotEmpty,
  validateEmail,
  getLeadIdFromLocalStorage,
  saveLeadIdToLocalStorage,
  getbrowserUUIDFromLocalStorage,
} from 'src/utils/utils';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { UnitPageContext } from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { NeighborhoodPageContext } from '../../pages/[urlParam]';
import { getContactFormContextData } from '../../src/utils/buildingUtils';

import { UnitFromQuickViewType } from 'types';
import PopUpThanks from '../PopUpThanks';
import Expert from '../Unit/Expert';
import { useAuth } from '../Auth/AuthProvider';
import { useMutation } from '@apollo/client';
import { SEND_CONTACT_US_FORM } from 'apolloClient/mutations/alerts';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useRouter } from 'next/router';
import Agents from 'components/Agents';
import { Agent, MappedAgent } from 'apolloClient/types/Agents';

type Props = {
  isHeaderForm: boolean;
  isRequestInfoBuilding?: boolean;
  isRequestInfoBuildingOwner?: boolean;
  isRequestInfoUnitOwner?: boolean;
  unitFromQuickView?: UnitFromQuickViewType;
  experts?: Agent[];
};

const ContactForm: React.FC<Props> = (props: Props) => {
  const {
    isHeaderForm,
    isRequestInfoBuilding,
    isRequestInfoBuildingOwner,
    isRequestInfoUnitOwner,
    unitFromQuickView,
    experts,
  } = props;
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [defaultAgent, setDefaultAgent] = useState<MappedAgent | null>(null);
  const router = useRouter();

  const { building: buildingData, url: buildingPageUrl } =
    useContext(BuildingPageContext) || {};
  const {
    listing,
    building: listingBuilding,
    neighborhood: listingNeighborhood,
    url: unitPageUrl,
  } = useContext(UnitPageContext) || {};
  const { neighborhood } = useContext(NeighborhoodPageContext) || {};
  const { me } = useAuth();
  const [fieldsValid, setFieldsValid] = useState({
    Name: true,
    Email: true,
    Comments: true,
    EmailPhone: true,
  });

  const [contactFormData, setContactFormData] = useState({
    Name: '',
    Phone: '',
    Email: '',
    Comments: '',
    FormLoaded6tY4bPYk: '',
    PhoneNumber6tY4bPYk: '',
    submitState: {
      isSubmitted: false,
      isTouched: false,
      isHeaderFormActive: false,
      submitting: false,
    },
  });
  const [dataType, setDataType] = useState(false);
  const [sendContactUsForm] = useMutation(SEND_CONTACT_US_FORM);

  //TODO: for unit page - get address info (backend logic; refer to legacy site)
  const defaultCommentsValue = unitFromQuickView
    ? `I'm interested in getting more information about UNIT #${unitFromQuickView?.unique} at ${unitFromQuickView?.building.data.attributes.name}, ${unitFromQuickView?.building.data.attributes.primaryAddress}`
    : buildingData?.name && isRequestInfoBuilding
    ? `Please send me the floor plan for ${buildingData?.name}`
    : buildingData?.name && isRequestInfoBuildingOwner
    ? `I own in ${buildingData?.name}. Please get me on regular updates on this building's sales and market reports.`
    : listing?.unitNumber && isRequestInfoUnitOwner
    ? `I would like to discuss selling my condo #${listing?.unitNumber} at ${listingBuilding?.name}, ${listing?.address?.streetNumber} ${listing?.address?.streetName}, ${listingNeighborhood?.city.data.attributes.name}, ${listing?.address?.zipCode}`
    : listing?.unitNumber || listing?.unit?.data?.attributes?.unique
    ? `I'm interested in getting more information about UNIT #${
        listing?.unitNumber || listing?.unit?.data?.attributes?.unique
      } at ${listingBuilding?.name}, ${listing?.address?.streetNumber} ${
        listing?.address?.streetName
      } ${listing?.address?.streetType}, ${
        listingNeighborhood?.city.data?.attributes?.name
      }, ${listing?.address?.zipCode}`
    : buildingData?.name
    ? `I'm interested in getting more information about ${buildingData.name}`
    : neighborhood?.name
    ? `I'm interested in getting more information about ${neighborhood?.name}`
    : '';

  useEffect(() => {
    setContactFormData((prevData) => ({
      ...prevData,
      Name: me?.fullName ?? '',
      Phone: me?.phoneNumber ?? '',
      Email: me?.email ?? '',
      Comments: defaultCommentsValue,
      FormLoaded6tY4bPYk: Date.now().toString(),
      agent: localStorage.getItem('agent') || router.query.agent?.toString(),
      leadId: localStorage.getItem('leadId'),
    }));
  }, [me, defaultCommentsValue]);

  const validateEmailPhone = (
    emailValue: string | undefined,
    phoneValue: string | undefined
  ) => {
    let valid = isNotEmpty(emailValue) || isNotEmpty(phoneValue);
    setFieldsValid({ ...fieldsValid, EmailPhone: valid });
    return valid;
  };

  const validateFields = () => {
    let nameValid = isNotEmpty(contactFormData.Name);
    let commentsValid = isNotEmpty(contactFormData.Comments);
    let emailValid = validateEmail(contactFormData.Email);
    let emailPhoneValid = validateEmailPhone(
      contactFormData.Email,
      contactFormData.Phone
    );

    if (emailPhoneValid && !emailValid && !isNotEmpty(contactFormData.Email)) {
      emailValid = true;
    }

    setContactFormData((prevState) => ({
      ...prevState,
      submitState: { ...prevState.submitState, isTouched: true },
    }));
    setFieldsValid({
      ...fieldsValid,
      Name: nameValid,
      Comments: commentsValid,
      Email: emailValid,
      EmailPhone: emailPhoneValid,
    });

    return nameValid && commentsValid && emailValid && emailPhoneValid;
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContactFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    if (contactFormData.submitState.isTouched) {
      if (e.target.name == 'Name' || e.target.name == 'Comments') {
        let valid = isNotEmpty(e.target.value);
        setFieldsValid({ ...fieldsValid, [e.target.name]: valid });
      } else if (e.target.name == 'Email') {
        let validEmail = validateEmail(e.target.value);
        let validEmailPhone = validateEmailPhone(
          e.target.value,
          contactFormData.Phone
        );
        setFieldsValid({
          ...fieldsValid,
          Email: validEmail,
          EmailPhone: validEmailPhone,
        });
      } else if (e.target.name == 'Phone') {
        let validEmailPhone = validateEmailPhone(
          contactFormData.Email,
          e.target.value
        );
        setFieldsValid({ ...fieldsValid, EmailPhone: validEmailPhone });
      }
    }
  };
  const eventContactUs = () => {
    window.gtag('event', 'contact_us', {
      event_category: 'contact_us',
      event_label: 'Success',
      value: 1,
    });
  };
  const onContactFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDataType(true);
    eventContactUs();
    const token = (await onVerify()) || '';
    if (validateFields()) {
      let payload = {
        postData: {
          ...contactFormData,
          ...getContactFormContextData(
            buildingData || listingBuilding,
            listing,
            buildingPageUrl,
            unitPageUrl,
            router.asPath
          ),
        },
        defaultMessage: defaultCommentsValue,
        isHeaderForm: isHeaderForm,
      };

      const checkForRent = () => {
        if (router.query.hasOwnProperty('forRent')) {
          return router.query['forRent'] == 'true' ? 'RENT' : '';
        }
        return '';
      };

      const leadIdFromLocalStorage = localStorage.getItem('leadId');

      if (payload.postData.LeadType == '')
        payload.postData.LeadType = checkForRent();

      setContactFormData((formData) => ({
        ...formData,
        submitState: { ...formData.submitState, submitting: true },
      }));

      const browserUUID = getbrowserUUIDFromLocalStorage() || undefined;

      sendContactUsForm({
        variables: {
          ...payload.postData,
          leadId: leadIdFromLocalStorage,
          browserUUID,
          RecaptchaToken: token,
        },
      })
        .then((data) => {
          try {
            const oldLeadId = getLeadIdFromLocalStorage() || null;
            const newLeadId = data?.data?.sendContactUsForm?.leadId;

            if ((!oldLeadId && newLeadId) || oldLeadId !== newLeadId) {
              saveLeadIdToLocalStorage(newLeadId);
            }
          } catch (error) {
            console.error(error);
          }
          try {
            setContactFormData((formData) => ({
              ...formData,
              submitState: {
                ...formData.submitState,
                isSubmitted: true,
                submitting: false,
              },
            }));
          } catch (error) {
            console.error(error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onVerify = useCallback(async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha('contact_form');
    return token;
  }, [executeRecaptcha]);

  const onClosingPopup = () => {
    setContactFormData((prevState) => ({
      ...prevState,
      submitState: { ...prevState.submitState, isSubmitted: false },
    }));
  };

  return isHeaderForm && contactFormData.submitState.isSubmitted ? (
    <div
      data-type={dataType && 'contact_us_header'}
      id="contact-us-header-ga4"
      className="flex flex-col border-t w-72 md:w-[420px] contact-us-success tab-pane active in border-beige-dark"
    >
      <div className="flex justify-center px-1 my-4 text-20-28-0.3 text-center font-sohneKraftig text-gold-darker">
        <span className="md:w-4/5">
          Your request has been sent,{' '}
          {defaultAgent ? defaultAgent.firstName : 'agent'} will contact you
          within 24 hours
        </span>
      </div>
      <div className="flex">
        <Agents
          experts={experts}
          showSecondAgent={false}
          onDefaultAgent={setDefaultAgent}
        />
      </div>
    </div>
  ) : (
    <>
      <form
        data-type={dataType && 'contact_us_body'}
        id="contact-us-body-ga4"
        className={classnames('flex flex-col', {
          'justify-center w-auto mt-8 text-16-26-0.3 align-center md:w-full':
            isHeaderForm,
          'text-16-26-0.3 md:w-[650px]': !isHeaderForm,
        })}
        onSubmit={onContactFormSubmit}
      >
        <input
          type="text"
          style={{ position: 'fixed', top: -100, right: -100 }}
          aria-label="PhoneNumber6tY4bPYk"
          value={contactFormData.PhoneNumber6tY4bPYk}
          name="PhoneNumber6tY4bPYk"
          onChange={handleChange}
          tabIndex={-1}
          autoComplete="off"
        />
        <input
          name="Name"
          className={classnames({
            'border border-gray form-input h-[46px] pl-[16px] text-16-21-0.3 md:text-14-21-0.3 ':
              isHeaderForm,
            'px-4 py-2 border border-gray h-[46px] text-16-21-0.3 md:text-14-21-0.3 ':
              !isHeaderForm,
          })}
          type="text"
          placeholder="Your Name"
          value={contactFormData.Name}
          onChange={handleChange}
        />
        {contactFormData.submitState.isTouched && !fieldsValid.Name && (
          <span className="field-validation-error">
            <span className="pl-3 text-red">Name is required</span>
          </span>
        )}
        <input
          name="Phone"
          className={classnames({
            'border border-gray -my-1 form-input h-[46px] pl-[16px] text-16-21-0.3 md:text-14-21-0.3 ':
              isHeaderForm,
            'px-4 py-2 -my-1 border border-gray h-[46px] text-16-21-0.3 md:text-14-21-0.3 ':
              !isHeaderForm,
          })}
          type="text"
          placeholder="Phone"
          value={contactFormData.Phone}
          onChange={handleChange}
        />
        <input
          name="Email"
          className={classnames({
            'border border-gray  form-input h-[46px] pl-[16px] text-16-21-0.3 md:text-14-21-0.3 ':
              isHeaderForm,
            'px-4 py-2  border border-gray h-[46px] text-16-21-0.3 md:text-14-21-0.3 ':
              !isHeaderForm,
          })}
          type="text"
          placeholder="Email"
          value={contactFormData.Email}
          onChange={handleChange}
        />

        {contactFormData.submitState.isTouched &&
          isNotEmpty(contactFormData.Email) &&
          !fieldsValid.Email && (
            <div className="field-validation-error">
              <div className="pl-3 text-red">Email address is invalid</div>
            </div>
          )}
        <textarea
          className={classnames({
            'border form-input h-[100px] mt-[27px] pl-[16px] pt-[15px] text-16-21-0.3 md:text-14-21-0.3 ':
              isHeaderForm,
            'px-[16px] pt-[17px] pb-2 border border-gray h-[100px] mt-[27px] text-16-21-0.3 md:text-14-21-0.3 ':
              !isHeaderForm,
          })}
          name="Comments"
          id=""
          cols={10}
          rows={3}
          placeholder="Your message"
          value={contactFormData.Comments}
          onChange={handleChange}
        />
        {contactFormData.submitState.isTouched && !fieldsValid.Comments && (
          <div className="field-validation-error">
            <div className="pl-3 text-red">Comments is required</div>
          </div>
        )}
        {contactFormData.submitState.isTouched && !fieldsValid.EmailPhone && (
          <div className="validation-error">
            <div className="px-4 py-2 alert alert-danger alert-dismissible text-red">
              Please specify YOUR PHONE or EMAIL in order to send your message.
              Thanks.
            </div>
          </div>
        )}
        <p className="pt-2 md:pb-2 md:pr-4 cs-legend-block text-13-20-0.3">
          I agree to be contacted by Condo Black Book LLC via call,
          email, and text. To opt-out, you can reply 'stop' at any
          time or click the unsubscribe link in the emails.
          Message and data rates may apply.{' '}
          <a
            target="_blank"
            href="/terms-of-use/"
            className="text-underline text-gold-darker"
          >
            Terms of Use
          </a>
          {' '}and{' '}
          <a
            target="_blank"
            href="/privacy-policy/"
            className="text-underline text-gold-darker"
          >
            Privacy Policy
          </a>
        </p>
        {isHeaderForm && (
          <button
            id="Contact_Us_Send_Header"
            className={classnames(
              'hover-animation self-end w-32 h-[44px] mt-6 text-12-18-0.3 uppercase',
              {
                ' bg-gold-hover hover:bg-gold-hover md:text-16-26-0.3 border-gold-hover hover:border-gold-hover w-[143px]':
                  contactFormData.submitState.submitting,
                'bg-gold hover:bg-gold-hover md:text-16-26-0.3  w-[143px]':
                  !contactFormData.submitState.submitting,
              }
            )}
          >
            {contactFormData.submitState.submitting ? 'Sending' : 'Send'}
          </button>
        )}
        {!isHeaderForm && (
          <PopUpThanks
            buttonIsLoading={contactFormData.submitState.submitting}
            popupOpen={
              !contactFormData.submitState.isHeaderFormActive &&
              contactFormData.submitState.isSubmitted
            }
            closePopup={onClosingPopup}
          />
        )}
      </form>
    </>
  );
};

export default ContactForm;
