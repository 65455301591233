import React from 'react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import classnames from 'classnames';

import shareHover from '../../../public/images/share.svg';
import share from '../../../public/images/share_white.svg';
import {
  centenaryNumberFormatter,
  priceFormatter,
} from '../../../src/utils/utils';
import { translateUnitStatus } from '../../../lib/translateUnitStatus';
import { flattenStrapiDataItem } from 'lib/flattenStrapiBulkDataItems';
import { getHrefForUnitLink } from '../utils/listingsFiltersUtils';
import { getBathsCount } from '../../../src/utils/listing';
import { getPropertyTypeByCode } from 'src/utils/getPropertyTypeByCode';
import { ORIGIN } from '../../../src/constants';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { defaultImage } from 'src/constants/constants';

import { FiltersValues } from 'components/UnitsFilters/types';
import PopUpSearchQuickView, {
  tabsCollectionKeys,
} from '../../PopUpSearchQuickView';
import ThreeDots from './ThreeDots';
import Share from '../../Share';
import SaveToFavoritesButton from 'components/SavedHouses/SaveToFavoritesButton';
import { Listing } from 'apolloClient/types/Units';
import { useAuth } from 'components/Auth/AuthProvider';
import { EntityWithId } from 'apolloClient/types';

export type CondoCardProps = {
  listing: Listing;
  index: number;
  showThreeDots?: boolean;
  mediumHeight?: boolean;
  largeHeight?: boolean;
  smallHeight?: boolean;
  showDeltaPrice?: boolean;
  isPPC?: boolean;
  isIconPPC?: boolean;
  imageClassName?: string;
  setFiltersValues?: (values: FiltersValues) => void;
  items?: EntityWithId<Listing>[];
};

const CondoCard: React.FC<CondoCardProps> = ({
  isIconPPC,
  isPPC,
  listing,
  index,
  items,
  showThreeDots = false,
  largeHeight = false,
  mediumHeight = false,
  smallHeight = false,
  showDeltaPrice = false,
  imageClassName,
  setFiltersValues,
}) => {
  const href = getHrefForUnitLink(listing);
  const unit = flattenStrapiDataItem(listing?.unit?.data);
  const building = flattenStrapiDataItem(unit?.building?.data);
  const neighborhood = flattenStrapiDataItem(building?.neighborhood?.data);

  const { bathsFull, bathsHalf, images } = listing || {};

  function filterTabs(): tabsCollectionKeys[] {
    const tabs: tabsCollectionKeys[] = ['photos'];
    listing.hasFloorplan && tabs.push('floorplan');
    building.lat && building.lon && tabs.push('direction');
    listing.virtualTourUrl && tabs.push('tour');
    return tabs;
  }

  function renderPriceDelta() {
    const delta = +centenaryNumberFormatter(
      ((listing.price - listing.previousPrice) * 100.0) / listing.previousPrice
    );

    if (
      listing.statusCode === 'CS' ||
      listing.statusCode === 'R' ||
      !listing.previousPrice ||
      !delta ||
      delta > 200
    )
      return null;

    if (delta > 0)
      return (
        <div
          title={`Was ${priceFormatter(listing.previousPrice)}`}
          className="text-12-18-0.3 font-extrabold md:text-16-26-0.3 text-azure font-sohneHalbfett"
        >
          Price Increased: +{delta}%
        </div>
      );
    if (delta < 0)
      return (
        <div
          title={`Was ${priceFormatter(listing.previousPrice)}`}
          className="text-12-18-0.3 font-extrabold md:text-16-26-0.3 text-red font-sohneHalbfett"
        >
          Price Reduced: {delta}%
        </div>
      );
  }
  const { me } = useAuth();
  return (
    <div>
      <div className="relative w-full">
        <div>
          <div
            className={classnames(
              `${imageClassName} relative h-[190px] md:h-[183px] lg:h-[173px]`,
              { 'lg:h-72 xl:h-[256px]': largeHeight },
              { 'h-56 xl:h-[256px]': mediumHeight },
              { 'h-40 xl:h-[256px]': smallHeight }
            )}
          >
            <Link
              href={href}
              passHref
              className="cursor-pointer image-wrapper"
              rel="nofollow"
            >
              <Image
                className="object-cover"
                layout="fill"
                src={
                  images?.data?.[0]?.attributes?.url
                    ? getSmallImageUrlFromImage(images?.data?.[0]?.attributes)
                    : defaultImage
                }
                blurDataURL={
                  images?.data?.[0]?.attributes?.url
                    ? getThumbnailImageUrlFromImage(
                        images?.data?.[0]?.attributes
                      )
                    : defaultImage
                }
                placeholder="blur"
                loading="eager"
                alt={`${building.name} #${listing.unitNumber}`}
              />
            </Link>
            <div className="absolute bottom-0 w-full h-12 space-x-2 shadow-top" />
            <div className="absolute bottom-0 flex items-center justify-end w-full h-12 pt-1 gap-[17px] right-[14px]">
              {(me?.id || (!isPPC && !isIconPPC)) && (
                <Share
                  copyData={`${ORIGIN}${href}`}
                  renderButton={() => (
                    <button className="cursor-pointer group">
                      <div className="group-hover:hidden">
                        <Image src={share} alt="share" height={25} width={25} />
                      </div>
                      <div className="hidden group-hover:block">
                        <Image
                          src={shareHover}
                          alt="share"
                          height={25}
                          width={25}
                        />
                      </div>
                    </button>
                  )}
                />
              )}
              {!!items?.length && (
                <PopUpSearchQuickView
                  isIconPPC={isIconPPC}
                  isPPC={isPPC}
                  items={items}
                  listing={listing}
                  activeUnitIndex={index}
                  tabsForRender={filterTabs()}
                />
              )}
              <SaveToFavoritesButton
                type={getPropertyTypeByCode(listing.propertyTypeCode)}
                unitId={unit.id}
                userFavorites={unit.favorite}
              />
              <ThreeDots
                showThreeDots={showThreeDots}
                listing={listing}
                building={building}
                setFiltersValues={setFiltersValues}
                unitStatus={translateUnitStatus(
                  listing.propertyTypeCode,
                  listing.statusCode,
                  listing.closeDate,
                  listing.isReo,
                  listing.isShortSale,
                  building.isPreconstruction,
                  building.yearBuilt
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <Link href={href} passHref>
        <div className="pt-[12px]">
          <div className="flex flex-col">
            <div className="text-16-26-0.3 uppercase font-sohneKraftig text-gold-darker p-[1px]">
              <div className="text-gold-darker">
                {translateUnitStatus(
                  listing.propertyTypeCode,
                  listing.statusCode,
                  listing.closeDate,
                  listing.isReo,
                  listing.isShortSale,
                  building.isPreconstruction,
                  listing.yearBuilt
                )}
              </div>
            </div>
            <div className="flex items-center justify-between mt-[3px] text-16-26-0.3 font-sohneKraftig lg:text-20-28-0.3">
              <div className="text-20-28-0.3 ">
                {priceFormatter(listing.price)}
              </div>
              {showDeltaPrice && (
                <div className="text-20-28-0.3 ">{renderPriceDelta()}</div>
              )}
            </div>
            <div className="">
              <div className="flex text-14-26-0.3 text-gray lg:text-16-26-0.3">
                <div className="pr-[10px] mr-[8px] border-r-[2px] border-gray text-16-26-0.3">
                  {listing.sqft || 0} sq ft
                </div>
                <div className="text-16-26-0.3">
                  {listing.bedsTotal === null ? '0' : listing.bedsTotal} beds,{' '}
                  {getBathsCount({ bathsFull, bathsHalf })} baths
                </div>
              </div>
              <div className="mt-[3px] mb-[6px] md:my-1 text-13-18-0.3 text-black lg:text-16-26-0.3 font-sohneKraftig">
                <h3 className="text-13-18-0.3 uppercase">
                  {building?.name || 'No name'} #{unit.unique}
                </h3>
                <div className="text-13-18-0.3">{neighborhood?.name}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-px mt-[6px] md:mt-[8px] xl:mt-[10px] w-fill bg-beige" />
      </Link>
    </div>
  );
};

export default CondoCard;
