import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import Breadcrumbs from '../Breadcrumbs';
import NameSection from './NameSection';
import BuildingCarousel from '../Building/BuildingCarousel';
import { flattenStrapiBulkDataItems } from '../../lib/flattenStrapiBulkDataItems';
import UnitPopupHeader from './UnitPopupHeader';
import TableUnit from './TableUnit';
import LineBetweenSections from '../LineBetweenSections';
import AboutUnit from './AboutUnit';
import KeyFeatures from './KeyFeatures';
import UnitFeatures from './UnitFeatures';
import AdditionalUnitDetails from './AdditionalUnitDetails';
import UnitFloorplanSection from './UnitFloorplanSection';
import RequestInfo from '../RequestInfo';
import PriceHistory from './PriceHistory';
import CondoValueEstimates from './CondoValueEstimates/CondoValueEstimates';
import MonthlyCostContainer from './MonthlyCost/MonthlyCostContainer';
import AboutSection from './AboutSection';
import RecentSales from '../RecentSales';
import RelatedArticles from '../RelatedArticles';
import Articles from '../Articles';
import { UNIT_DISPLAY_COUNTER } from '../../src/constants/constants';
import { getATTFromLocalStorage, wasOnceLogged } from '../../src/utils/utils';
import ErrorBoundary from '../ErrorBoundary';

import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { Metadata } from 'components/Metadata';
import { getStrapiMedia } from 'lib/getStrapiMedia';
import { Blog } from 'apolloClient/types/Blog';
import { useAuth } from 'components/Auth/AuthProvider';
// import { getBathsCount } from '../../src/utils/listing';
// import CalculateSection from './CalculateSection';
import Header from 'components/layouts/Header/Header';
import { Theme, useMediaQuery } from '@mui/material';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import { isListingOffMarket } from 'lib/checkListingStatus';
import GoogleAnalytics from 'src/ga/grouping';
import HeaderUnitPage from 'components/layouts/Header/HeaderUnitPage';
import { useRouter } from 'next/router';
import { usePPC } from 'components/PPCProvider';

const UnitPage: React.FC = () => {
  const { asPath } = useRouter();
  const { isPPC } = usePPC();
  const { openHeaderPopup, me, isLogged } = useAuth();

  const { building, unit, listing, blogs, neighborhood, url, city, experts } =
    useContext(UnitPageContext) as UnitPageContextInterface;

  const { propertyTypeCode, statusCode } = listing;

  const isUnitInPreconstruction =
    listing?.unit?.data?.attributes?.building?.data?.attributes
      ?.isPreconstruction;
  const isForRent = Boolean(propertyTypeCode === 'R');
  const isActiveProperty = !isListingOffMarket(statusCode);
  let forRentOrSale = isActiveProperty
    ? isForRent
      ? 'for Rent'
      : 'for Sales'
    : '';
  const title = `${building.name} Unit #${listing.unitNumber} ${forRentOrSale} in ${neighborhood.name} - ${city.name} | CondoBlackBook`;
  const description = `Explore ${building.name} Unit #${listing.unitNumber} ${forRentOrSale} at ${building.primaryAddress} in ${neighborhood.name} - ${city.name}, with real-time availability, photos, floor plans, price history, search similar condos for sale or rent, and latest news.`;
  const images = flattenStrapiBulkDataItems(listing.images?.data);
  const positionOnTheMap =
    building.lon && building.lat
      ? {
          lng: building.lon,
          lat: building.lat,
        }
      : undefined;

  const [isIconPPC, setIconIsPPC] = useState(false);

  useEffect(() => {
    checkPPCAttribute();
  }, [isPPC, asPath]);

  function checkPPCAttribute() {
    const att = getATTFromLocalStorage();
    const unitDisplayCounter = localStorage.getItem(UNIT_DISPLAY_COUNTER);
    if (!me?.id && !wasOnceLogged() && att && unitDisplayCounter) {
      if (!isLogged && att <= unitDisplayCounter && listing.price < 2000000) {
        openHeaderPopup({ keepOpen: true });
      } else {
        localStorage.setItem(
          UNIT_DISPLAY_COUNTER,
          String(Number(unitDisplayCounter) + 1)
        );
      }
    }
  }

  // const listingDataForFUBEvent = useMemo(
  //   () => ({
  //     buildingName: building.name,
  //     unitNumber: listing.unitNumber,
  //     property: {
  //       street: building.primaryAddress,
  //       forRent: forRentOrSale === 'for Rent',
  //       price: listing.price,
  //       code: listing.address && listing.address.zipCode,
  //       mlsNumber: listing.mlsListingId,
  //       city: city.name,
  //       area: String(listing.sqft),
  //       bathrooms: String(
  //         getBathsCount({
  //           bathsFull: listing.bathsFull,
  //           bathsHalf: listing.bathsHalf,
  //         })
  //       ),
  //       bedrooms: String(listing.bedsTotal),
  //     },
  //   }),
  //   [listing, building, city]
  // );

  const showVirtualTour = !!listing.virtualTourUrl
    ? (listing.statusCode === 'A' ||
        listing.statusCode === 'AC' ||
        listing.statusCode === 'PS') &&
      (!listing.virtualTourUrl.includes('propertypanorama') ||
        !listing.virtualTourUrl.includes('immoviewer') ||
        !listing.virtualTourUrl.includes('corelistingmachine')) &&
      listing.virtualTourUrl !== null
      ? listing.virtualTourUrl
      : ''
    : '';
  const show3DWalkthrough =
    !!showVirtualTour &&
    !showVirtualTour.startsWith('http') &&
    `https://${showVirtualTour}`;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const hiddenListings = [
    'A10378194',
    'A10240593',
    'F10309906',
    'F10203206',
    'F10326762',
    'A11202780',
    'A10962840',
    'A10797965',
    'A11154866',
    'A10948054',
    'A11013784',
    'A10329661',
    'A10274004',
    'A11118310',
    'A11088933',
    'A10750819',
    'F10305805',
    'F10305981',
    'F10306117',
    'A10967035',
    'F10160993',
    'F10234985',
    'A10719662',
    'A10754336',
    'F10309986',
    'F10302640',
    'F10229591',
    'A11018401',
  ];
  const hiddenStatusCodes = ['C', 'W', 'X'];
  const hidePhotos =
    hiddenListings.includes(listing.mlsListingId) ||
    hiddenStatusCodes.includes(listing.statusCode);

  const RentOrSaleRef = useRef<any>(null);
  const FeaturesAndAmenitiesRef = useRef<any>(null);
  // const UnitAmenitiesRef = useRef<any>(null);
  const FloorplansRef = useRef<any>(null);
  const HistoryRef = useRef<any>(null);
  const AboutNeighborhoodRef = useRef<any>(null);
  const headerRef = useRef(null);

  const history = listing.unit.data.attributes.history;
  const linksForHeader = useMemo(
    () => [
      {
        id: 'RentOrSale',
        name: `${isForRent ? 'For Rent' : 'For Sale'}`,
        nameMobile: 'RentOrSale',
        section: 'RentOrSale',
        ref: RentOrSaleRef,
      },
      {
        id: 'FeaturesAndAmenities',
        name: 'Features & Amenities',
        nameMobile: 'Features & Amenities',
        section: 'FeaturesAndAmenities',
        ref: FeaturesAndAmenitiesRef,
      },
      {
        id: 'History',
        name: 'History',
        nameMobile: 'History',
        section: 'History',
        ref: HistoryRef,
      },
      {
        id: 'AboutNeighborhood',
        name: `About ${neighborhood.name}`,
        nameMobile: `About ${
          neighborhood.name.length > 5
            ? `${neighborhood.name.slice(0, 4)}...`
            : neighborhood.name
        }`,
        section: 'AboutNeighborhood',
        ref: AboutNeighborhoodRef,
      },
    ],
    []
  );

  const linksForHeaderWithoutHistory = useMemo(
    () => [
      {
        id: 'RentOrSale',
        name: `${isForRent ? 'For Rent' : 'For Sale'}`,
        nameMobile: 'RentOrSale',
        section: 'RentOrSale',
        ref: RentOrSaleRef,
      },
      {
        id: 'FeaturesAndAmenities',
        name: 'Features & Amenities',
        nameMobile: 'Features & Amenities',
        section: 'FeaturesAndAmenities',
        ref: FeaturesAndAmenitiesRef,
      },
      {
        id: 'AboutNeighborhood',
        name: `About ${neighborhood.name}`,
        nameMobile: `About ${
          neighborhood.name.length > 5
            ? `${neighborhood.name.slice(0, 4)}...`
            : neighborhood.name
        }`,
        section: 'AboutNeighborhood',
        ref: AboutNeighborhoodRef,
      },
    ],
    []
  );
  const router = useRouter();

  const isUnitPagePpc = router.query.att;
  useEffect(() => {
    if (isUnitPagePpc && +isUnitPagePpc >= 1) {
      setIconIsPPC(true);
    }
  }, []);

  return (
    <>
      <Metadata
        {...{
          title,
          image: getStrapiMedia(images[0], 'small'),
          description,
          url,
        }}
      />
      <GoogleAnalytics
        pageGroup={listing?.propertyTypeCode === 'R' ? 'Rental' : 'Sale'}
      />
      {isMobile ? (
        <Header />
      ) : (
        <HeaderUnitPage
          links={
            history?.length > 0 ? linksForHeader : linksForHeaderWithoutHistory
          }
          headerRef={headerRef}
        />
      )}
      <div className="md:pt-[70px] lg:pt-[40px]">
        <Breadcrumbs
          neighborhoodId={building.neighborhood.data.attributes.slug}
          neighborhoodName={building.neighborhood.data.attributes.name}
          buildingName={building.name}
          buildingId={building.slug}
          unitPage={true}
          unitName={unit.unique}
          unitUrl={url}
        />
      </div>
      <NameSection />
      <ErrorBoundary>
        <BuildingCarousel
          nameButton="Floor plan"
          images={hidePhotos ? [] : images}
          position={
            building.lon && building.lat
              ? {
                  lat: building.lat,
                  lng: building.lon,
                }
              : undefined
          }
          renderHeader={<UnitPopupHeader />}
          tours={show3DWalkthrough || showVirtualTour}
        />
      </ErrorBoundary>
      <div ref={RentOrSaleRef} className="relative">
        <TableUnit />
      </div>
      {/* <ErrorBoundary>{!isForRent && <CalculateSection />}</ErrorBoundary> */}
      <LineBetweenSections hideLine={'hidden container'} />
      <AboutUnit experts={experts} />
      <LineBetweenSections hideLine={'md:hidden container'} />
      <ErrorBoundary>
        <div className="relative">
          <div className="absolute top-[-127px] md:top-[-144px] xl:top-[-75px]" />
          <KeyFeatures
            floorplanCoordinates={FloorplansRef?.current?.offsetTop}
          />
        </div>
      </ErrorBoundary>
      <LineBetweenSections hideLine={'sm:hidden container'} />
      <div className="relative" ref={FeaturesAndAmenitiesRef}>
        <div
          id="FeaturesAndAmenities"
          className="absolute top-[-120px] md:top-[-118px] xl:top-[-30px]"
        />
        <UnitFeatures />
      </div>
      <LineBetweenSections hideLine={'sm:hidden container'} />
      <AdditionalUnitDetails />
      <div className="relative" ref={FloorplansRef}>
        <div
          id="FeaturesAndAmenities"
          className="absolute top-[-63px] md:top-[-95px] xl:top-[-20px]"
        />
        <LineBetweenSections hideLine={'container pt-[40px] md:pt-[22px]'} />
        <UnitFloorplanSection
          positionOnTheMap={positionOnTheMap}
          entity={listing}
          images={images}
        />
      </div>
      <ErrorBoundary>
        <div className="relative">
          <div className="absolute top-[-110px] md:top-[-130px] xl:-top-10" />
          <div className="relative px-0 pt-[22.5px] md:pt-[28px] mb-6 xl:px-32 text-m bg-beige-light">
            <RequestInfo experts={experts} />
          </div>
        </div>
      </ErrorBoundary>
      <div className="relative" ref={HistoryRef}>
        <div
          id="History"
          className="absolute top-[-120px] md:top-[-118px] xl:top-[-30px]"
        />
        <PriceHistory />
      </div>
      {!isUnitInPreconstruction && <CondoValueEstimates experts={experts} />}
      {(isActiveProperty || statusCode === 'PS') && !isForRent && (
        <MonthlyCostContainer />
      )}
      <div className="relative" ref={AboutNeighborhoodRef}>
        <div
          id="AboutNeighborhood"
          className="absolute top-[-100px] md:top-[-130px] xl:top-[-50px]"
        />
        <AboutSection isPPC={isPPC} isIconPPC={isIconPPC} />
      </div>
      <section className="container relative md:mt-2 md:pt-0 section lg:pt-0 lg:mt-0 md:pb-0 text-m">
        <ErrorBoundary>
          <RecentSales
            name={building.name}
            urlBuildParam={building.slug}
            showLine={true}
          />
        </ErrorBoundary>
      </section>
      <ErrorBoundary>
        <RelatedArticles
          name="Related articles"
          showButtonMore="pb-[28px] pt-[30px] md:pt-0 md:pb-0 md:mb-[67px] !mt-0 md:!mt-[76px]"
        >
          {blogs?.map((blog: Blog) => {
            return (
              <Articles
                href={`/blog/${blog.slug}`}
                key={blog.id}
                topics={blog?.blogTags}
                {...(blog?.mainImage?.data?.attributes
                  ? {
                      imageUrl: getSmallImageUrlFromImage(
                        blog?.mainImage?.data?.attributes
                      ),
                      blurredImageUrl: getThumbnailImageUrlFromImage(
                        blog?.mainImage?.data?.attributes
                      ),
                    }
                  : {})}
                imageAlt={blog.title}
                title={blog.title}
                description={blog.shortDescription || ''}
              />
            );
          })}
        </RelatedArticles>
      </ErrorBoundary>
    </>
  );
};

export default UnitPage;
