import React, { useContext } from 'react';
import Image from 'next/legacy/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import unitRightArrowBig from '../../public/images/unit_right_arrow.svg';
import unitLeftArrowBig from '../../public/images/unit_left_arrow.svg';
import Button from '../../components/Button';
// import arrowLeft from '../../public/images/arrow-left.svg';
// import arrowDown from '../../public/images/arrow-down.svg';
import {
  UnitPageContext,
  UnitPageContextInterface,
} from '../../pages/[urlParam]/[urlBuildParam]/[urlUnitParam]';
import { createSearchUrlByType } from '../Search/utils/listingsFiltersUtils';
import {
  Box,
  Divider,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import CondoCard from 'components/Search/CondosSearchResult/CondoCard';
// import MarkdownParser from '../MarkdownParser';
import ErrorBoundary from 'components/ErrorBoundary';
import { getPropertyTypeByCode } from 'src/utils/getPropertyTypeByCode';
// import classnames from 'classnames';
import LineBetweenSections from 'components/LineBetweenSections';
// import { Listing } from 'apolloClient/types/Units';
// import { EntityWithId } from 'apolloClient/types';

type propsAboutSection = {
  isPPC?: boolean;
  isIconPPC?: boolean;
};

const AboutSection: React.FC<propsAboutSection> = ({ isPPC, isIconPPC }) => {
  const { listing, nearbyCondos, neighborhood, nearbyCondosTotal } = useContext(
    UnitPageContext
  ) as UnitPageContextInterface;
  const imagesCarousel = Boolean(nearbyCondos && nearbyCondos.length > 1);
  const lessMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  function NearbyCondosPrevArrow({ currentSlide, slideCount, ...props }: any) {
    return (
      <button
        {...props}
        className="z-50 transform md:!left-[20px] lg:!left-[40px] slick-arrow slick-prev slick-prev-nearby-condos ml-[-15px] md:ml-[5px] lg:ml-[-15px] mt-[14px] md:mt-[-3px] lg:mt-[26px] xl:mt-[17px]"
      >
        <Image src={unitLeftArrowBig} alt="previous" width="28" height="48" />
      </button>
    );
  }
  function NearbyCondosNextArrow({ currentSlide, slideCount, ...props }: any) {
    return (
      <button
        {...props}
        className="z-50 mt-[14px] transform slick-arrow slick-next slick-next-nearby-condos md:mt-[-3px] lg:mt-[26px] xl:mt-[17px]"
      >
        <Image src={unitRightArrowBig} alt="next" width="28" height="48" />
      </button>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      imagesCarousel && nearbyCondos.length > 2 ? 3 : nearbyCondos.length,
    className:
      nearbyCondos.length === 1 && !lessMd
        ? 'w-1/3'
        : nearbyCondos.length === 2 && !lessMd
        ? 'w-2/3'
        : 'w-full',
    slidesToScroll: 1,
    prevArrow: <NearbyCondosPrevArrow />,
    nextArrow: <NearbyCondosNextArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow:
            imagesCarousel && nearbyCondos.length > 2 ? 3 : nearbyCondos.length,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Preloader = () => (
    <>
      {new Array(1).fill(null).map((_, i) => (
        <Box pt={3} key={i}>
          {new Array(10).fill(null).map((__, j) => (
            <Typography key={j} variant="h3">
              <Skeleton />
            </Typography>
          ))}
          <Divider sx={{ marginTop: 3 }} />
        </Box>
      ))}
    </>
  );
  return (
    <ErrorBoundary>
      {/*{neighborhood?.shortDescription && (*/}
      {/*  <section className="container pt-[38px] md:pt-[30px]">*/}
      {/*    <div className="flex ">*/}
      {/*      <div className="items-center justify-center hidden w-10 mx-2 md:flex lg:w-16">*/}
      {/*        <div className="w-10 lg:w-16 h-0.5 bg-black"/>*/}
      {/*      </div>*/}
      {/*      <div*/}
      {/*        className="justify-center text-24-32-0.3  uppercase whitespace-pre-line w-max lg:text-30-36-1 md:mx-4 font-sohneBreitBuch">*/}
      {/*        <button className="flex items-start text-left uppercase">*/}
      {/*          <div className="block w-8 mr-1 md:hidden">*/}
      {/*            <Image src={arrowDown} alt="About"/>*/}
      {/*          </div>*/}
      {/*          <h2 className="whitespace-pre-line">*/}
      {/*            About&nbsp;*/}
      {/*            {neighborhood.name}*/}
      {/*          </h2>*/}
      {/*        </button>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className="relative mt-6 md:mt-[6px] ellipsis md:leading-6 md:pr-1 lg:leading-7 xl:w-1/2">*/}
      {/*      <div*/}
      {/*        className={classnames('max-h-20 lg:max-h-40 h-0', {*/}
      {/*          'h-30 md:h-40': neighborhood?.shortDescription,*/}
      {/*        })}*/}
      {/*      >*/}
      {/*        {neighborhood.shortDescription && (*/}
      {/*          <MarkdownParser*/}
      {/*            markdown={neighborhood.shortDescription}*/}
      {/*            classNames="text-16-26-0.3"*/}
      {/*          />*/}
      {/*        )}*/}
      {/*        <div*/}
      {/*          className="absolute bottom-0 w-full h-10"*/}
      {/*          style={{*/}
      {/*            background: 'linear-gradient(rgba(255,255,255,0),#fff)',*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <a*/}
      {/*      href={`/${neighborhood.slug}`}*/}
      {/*      className="flex items-center w-auto pl-2 mt-4 uppercase cursor-pointer border-gold md:mt-8 md:mb-10 lg:my-6"*/}
      {/*    >*/}
      {/*      <div className="flex items-center border-b-2 border-gold">*/}
      {/*        <div className="w-5 h-4">*/}
      {/*          <Image*/}
      {/*            src={arrowLeft}*/}
      {/*            alt={`Learn more about ${neighborhood.name}`}*/}
      {/*            width={15}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*        <span className="pt-1">*/}
      {/*            Learn more about {neighborhood.name}*/}
      {/*          </span>*/}
      {/*      </div>*/}
      {/*    </a>*/}
      {/*  </section>*/}
      {/*)}*/}
      {nearbyCondosTotal > 0 && (
        <>
          <section className="container pt-6 pb-2 md:pb-[43px] lg:pb-[69px] md:pt-[30px] container-carousel lg:pt-12 text-m">
            <p className="text-16-26-0.3 font-sohneKraftig md:text-20-28-0.3 lg:ml-[20px]">
              See {nearbyCondosTotal || 0}
              &nbsp; Other Condos Available in the&nbsp;
              {neighborhood.name}
            </p>
            <div className="relative mb-2 md:mb-4 lg:mb-14 mt-[27px]">
              <div className="relative">
                {!!nearbyCondosTotal ? (
                  <Slider {...settings}>
                    {nearbyCondos
                      ?.filter((it) => it.id !== listing.id)
                      .map((item, index) => {
                        return (
                          <div
                            key={item.id}
                            className="md:px-[8px] lg:px-[20px] lg:!w-[406px]"
                          >
                            <CondoCard
                              isIconPPC={isIconPPC}
                              isPPC={isPPC}
                              listing={item.attributes}
                              index={index}
                              largeHeight={true}
                              showDeltaPrice={true}
                            />
                          </div>
                        );
                      })}
                  </Slider>
                ) : (
                  <Preloader />
                )}
              </div>
            </div>
            <div className="flex items-center justify-center mx-auto mt-16 md:w-1/2 lg:w-[366px] ">
              <Button
                href={createSearchUrlByType(
                  getPropertyTypeByCode(listing.propertyTypeCode),
                  {
                    neighborhoods: [neighborhood.slug],
                  }
                )}
                name="View more"
              />
            </div>
          </section>
          <LineBetweenSections hideLine={'container lg:pb-[4px]'} />
        </>
      )}
    </ErrorBoundary>
  );
};

export default AboutSection;
