import React, { useContext, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import Image from 'next/legacy/image';

import Breadcrumbs from '../Breadcrumbs';
import Share from '../Share';
import BuildingCarousel from './BuildingCarousel';
import { flattenStrapiBulkDataItems } from '../../lib/flattenStrapiBulkDataItems';
import BuildingPopupHeader from './BuildingPopupHeader';
import PreconstructionTableSection from './Preconstruction/PreconstructionTableSection';
import TableSection from './TableSection';
import PreconstructionAboutSection from './Preconstruction/PreconstructionAboutSection';
import AboutSection from './AboutSection';
import BuildingAmenities from './BuildingAmenities';
import RequestInfo from '../RequestInfo';
import AvailableCondos from './AvailableCondos/AvailableCondos';
import GuidesSection from './GuidesSection';
import BuildingFloorplansSection from './BuildingFloorplansSection';
import BuildingStats from './BuildingStats/BuildingStats';
import LineBetweenSections from '../LineBetweenSections';
import RecentSales from '../RecentSales';
import NearbyCondos from './NearbyCondos';
import RelatedArticles from '../RelatedArticles';
import Articles from '../Articles';
import { BuildingPageContextInterface } from '../../apolloClient/types/Building';
import ErrorBoundary from '../ErrorBoundary';

import { BuildingPageContext } from '../../pages/[urlParam]/[urlBuildParam]';
import { PropertyType } from 'components/UnitsFilters/types';
import { Metadata } from 'components/Metadata';
import { getStrapiMedia } from 'lib/getStrapiMedia';
import { Blog } from 'apolloClient/types/Blog';
import HeaderWithLinks from '../layouts/Header/HeaderWithLinks';
import {
  getSmallImageUrlFromImage,
  getThumbnailImageUrlFromImage,
} from 'lib/getImages';
import GoogleAnalytics from 'src/ga/grouping';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { usePPC } from 'components/PPCProvider';

const BuildingPage: React.FC = () => {
  const { isPPC } = usePPC();
  const { building, blogs, neighborhood, url, experts } = useContext(
    BuildingPageContext
  ) as BuildingPageContextInterface;

  const {
    slug,
    name,
    images,
    isPreconstruction,
    primaryAddress,
    videoUrl,
    lat,
    lon,
    floorplans,
  } = building;

  const [availableCondosPropertyType, setAvailableCondosPropertyType] =
    useState<PropertyType>('sale');

  const headerRef = useRef(null);
  const rentAndBuyRef = useRef(null);
  const aboutRef = useRef(null);
  const buildingAmenitiesRef = useRef(null);
  // const CBBRankRef = useRef(null);
  const AvailableCondosRef = useRef<any>(null);
  const FloorplansRef = useRef(null);
  const BuildingStatsRef = useRef(null);
  const EdgewaterCondosRef = useRef(null);
  const GuidesSectionRef = useRef(null);
  const RequestInfoRef = useRef(null);
  // const SectionScrollRef = useRef<any>(null);

  const linksForHeader = useMemo(
    () => [
      {
        id: 'BuildingAmenities',
        name: 'Building Amenities',
        nameMobile: 'Amenities',
        section: 'BuildingAmenities',
        ref: buildingAmenitiesRef,
      },
      {
        id: 'AvailableCondos',
        name: 'For Sale and Rent',
        nameMobile: 'For Sale and Rent',
        section: 'AvailableCondos',
        ref: AvailableCondosRef,
      },
      {
        id: 'Floorplans',
        name: 'Floorplans',
        nameMobile: 'Floorplans',
        section: 'Floorplans',
        ref: FloorplansRef,
      },
      {
        id: 'BuildingStats',
        name: 'Building Stats',
        nameMobile: 'Stats',
        section: 'BuildingStats',
        ref: BuildingStatsRef,
      },
      {
        id: 'RequestInfo',
        name: 'Request Info',
        nameMobile: 'Request Info',
        section: 'RequestInfo',
        ref: RequestInfoRef,
      },
    ],
    []
  );

  const linksForPreconstructionHeader = useMemo(
    () => [
      {
        id: 'BuildingAmenities',
        name: 'Building Amenities',
        nameMobile: 'Amenities',
        section: 'BuildingAmenities',
        ref: buildingAmenitiesRef,
      },
      {
        id: 'RequestInfo',
        name: 'Request Info',
        nameMobile: 'Request Info',
        section: 'RequestInfo',
        ref: RequestInfoRef,
      },
      {
        id: 'AvailableCondos',
        name: 'For Sale and Rent',
        nameMobile: 'For Sale and Rent',
        section: 'AvailableCondos',
        ref: AvailableCondosRef,
      },
      {
        id: 'Floorplans',
        name: 'Floorplans',
        nameMobile: 'Floorplans',
        section: 'Floorplans',
        ref: FloorplansRef,
      },
    ],
    []
  );
  const router = useRouter();

  const [isIconPPC, setIconIsPPC] = useState(false);

  const isBuildingPagePpc = router.query.att;
  function PPC() {
    if (isBuildingPagePpc && +isBuildingPagePpc >= 0) {
      setIconIsPPC(true);
    }
  }
  setTimeout((args: void) => {
    PPC();
  }, 0);

  if (!slug) return null;

  const flattenedImages = flattenStrapiBulkDataItems(images.data);
  const cityName = neighborhood?.city?.data?.attributes?.name;

  const title = `${building.name} Condos for Sale and Rent in ${neighborhood.name} - ${cityName} | CondoBlackBook`;
  const description = `Explore and search ${building.name}, ${building.primaryAddress}, with real-time sale and rental availability, photos, videos, floor plans, condo search, recent sales and latest news.`;

  return (
    <>
      <Metadata
        {...{
          title,
          image: getStrapiMedia(flattenedImages[0], 'small'),
          description,
          url,
        }}
      />
      <main>
        {isPreconstruction ? (
          <HeaderWithLinks
            links={linksForPreconstructionHeader}
            headerRef={headerRef}
          />
        ) : (
          <HeaderWithLinks links={linksForHeader} headerRef={headerRef} />
        )}
        <GoogleAnalytics pageGroup={'Building'} />
        <div>
          <section
            ref={rentAndBuyRef}
            className={classNames('relative  section', {
              // 'pt-0': isPreconstruction, // why?
              // 'pt-14 md:pt-20 lg:pt-10': !isPreconstruction,
              'pt-14 md:pt-20 lg:pt-10': true,
            })}
          >
            <div id="RentAndBuy" className="absolute -top-28" />
            {isPreconstruction ? (
              <ErrorBoundary>
                <Breadcrumbs
                  neighborhoodId={neighborhood.slug}
                  neighborhoodName={neighborhood.name}
                  buildingName={name}
                  buildingId={slug}
                  buildingPage={true}
                  preConstructionPage={true}
                />
              </ErrorBoundary>
            ) : (
              <ErrorBoundary>
                <Breadcrumbs
                  neighborhoodId={neighborhood.slug}
                  neighborhoodName={neighborhood.name}
                  buildingName={name}
                  buildingId={slug}
                  buildingPage={true}
                />
              </ErrorBoundary>
            )}
            <div className="container text-m md:pt-[8px] lg:pt-0">
              <div className="relative flex flex-row justify-between md:items-end">
                <div>
                  <h1 className="px-px pt-1 uppercase font-sohneBreitBuch text-23-29-0.77 xl:text-24-47-0.8">
                    {name}
                  </h1>
                </div>
                <div className="relative flex pt-px mb-2 space-x-2 text-16-26-0.3 md:absolute md:right-0 md:-top-14 lg:-top-2 lg:relative md:mb-0">
                  <div className="mt-0.5 md:mt-[12px] md:ml-[12px]">
                    <Share visibility="hidden md:block" />
                  </div>
                </div>
              </div>
              <p className="md:pt-[6px] lg:pt-[0] text-14-21-0.3 md:text-14-26-0.3 w-[90%]">
                {primaryAddress}
              </p>
            </div>
            <ErrorBoundary>
              <BuildingCarousel
                nameButton="Floor plans"
                images={flattenedImages}
                position={
                  lon && lat
                    ? {
                        lng: lon,
                        lat: lat,
                      }
                    : undefined
                }
                renderHeader={<BuildingPopupHeader />}
                tours={videoUrl
                  ?.split(',')
                  .map((url: string) => url.trim())
                  .filter((url) => url?.length > 0)}
              />
            </ErrorBoundary>
            {isPreconstruction ? (
              <ErrorBoundary>
                <PreconstructionTableSection
                  availableCondosCoordinates={
                    AvailableCondosRef?.current?.offsetTop
                  }
                  setAvailableCondosPropertyType={
                    setAvailableCondosPropertyType
                  }
                  href="#RequestInfo"
                />
              </ErrorBoundary>
            ) : (
              <ErrorBoundary>
                <TableSection
                  availableCondosCoordinates={
                    AvailableCondosRef?.current?.offsetTop
                  }
                  setAvailableCondosPropertyType={
                    setAvailableCondosPropertyType
                  }
                />
              </ErrorBoundary>
            )}
          </section>
          {isPreconstruction ? (
            <ErrorBoundary>
              <PreconstructionAboutSection
                href="#RequestInfo"
                experts={experts}
              />
            </ErrorBoundary>
          ) : (
            <section
              ref={aboutRef}
              className="container relative pb-6 md:pb-0 section lg:pt-2 lg:pb-0 text-m"
            >
              <ErrorBoundary>
                <AboutSection href="#BuildingStats" />
              </ErrorBoundary>
            </section>
          )}
          <section
            ref={buildingAmenitiesRef}
            className="container relative section"
          >
            <div
              id="BuildingAmenities"
              className="absolute -top-28 lg:-top-24"
            />
            <ErrorBoundary>
              <BuildingAmenities />
            </ErrorBoundary>
          </section>
        </div>
        {isPreconstruction && (
          <section
            ref={RequestInfoRef}
            className="relative px-0 pt-6 mt-10 xl:px-32 text-m bg-beige-light"
          >
            <div id="RequestInfo" className="absolute -top-20" />
            <ErrorBoundary>
              <RequestInfo experts={experts} />
            </ErrorBoundary>
          </section>
        )}
        <React.Fragment>
          {/* We will work on this section in phase 2 */}
          {/* <LineBetweenSections hideLine={'sm:hidden container'} /> */}
          {/* <section ref={CBBRankRef} className="container relative"><SectionRankCBB /></section> */}
          <section
            ref={AvailableCondosRef}
            className="container relative pt-2 pb-8 lg:pb-[48px] section text-m"
          >
            <div id="AvailableCondos" className="absolute -top-14 md:-top-12" />
            <ErrorBoundary>
              <AvailableCondos
                isIconPPC={isIconPPC}
                isPPC={isPPC}
                initialPropertyType={availableCondosPropertyType}
              />
            </ErrorBoundary>
          </section>
        </React.Fragment>
        <section ref={GuidesSectionRef} className="relative text-14-26-0.3">
          <Image
            layout="fill"
            src="/images/guides-background-min.jpg"
            className="absolute w-full h-full"
            alt=""
          />
          <div id="GuidesSection" className="absolute -top-32 md:-top-24" />
          <GuidesSection />
        </section>
        <section
          ref={FloorplansRef}
          className="container relative py-10 section text-m"
        >
          <div
            id="Floorplans"
            className="absolute -top-10 md:-top-14 lg:-top-12"
          />
          <ErrorBoundary>
            <BuildingFloorplansSection
              floorplans={floorplans}
              position={
                lon && lat
                  ? {
                      lng: lon,
                      lat: lat,
                    }
                  : undefined
              }
            />
          </ErrorBoundary>
        </section>
        {isPreconstruction ? null : (
          <React.Fragment>
            <section
              ref={BuildingStatsRef}
              className="container relative pt-2 mt-4 section lg:pt-2 md:pb-0 text-m"
            >
              <div id="BuildingStats" className="absolute -top-4 md:-top-10" />
              <ErrorBoundary>
                <BuildingStats
                  href="#BuildingRecentSales"
                  showButton={classnames({
                    block: Number(building.unitsCount) > 0,
                    hidden: Number(building.unitsCount) === 0,
                  })}
                />
              </ErrorBoundary>
              <LineBetweenSections hideLine={'md:hidden'} />
              <div className="relative">
                <div id="BuildingRecentSales" className="absolute -top-20" />
                <ErrorBoundary>
                  <RecentSales name={name} urlBuildParam={slug} />
                </ErrorBoundary>
              </div>
            </section>
            <section
              ref={RequestInfoRef}
              className="relative px-0 pt-6 mb-6 xl:px-32 text-m bg-beige-light"
            >
              <div id="RequestInfo" className="absolute -top-10" />
              <ErrorBoundary>
                <RequestInfo experts={experts} />
              </ErrorBoundary>
            </section>
          </React.Fragment>
        )}
        <section ref={EdgewaterCondosRef} className="relative">
          <div id="EdgewaterCondos" className="absolute -top-28" />
          <ErrorBoundary>
            <NearbyCondos />
          </ErrorBoundary>
        </section>
        <ErrorBoundary>
          <RelatedArticles name="Related articles" showButtonMore="mb-[67px]">
            {blogs?.map((blog: Blog, index) => {
              return (
                <Articles
                  href={`/blog/${blog.slug}`}
                  key={index}
                  topics={blog?.blogTags}
                  {...(blog?.mainImage?.data?.attributes
                    ? {
                        imageUrl: getSmallImageUrlFromImage(
                          blog?.mainImage?.data?.attributes
                        ),
                        blurredImageUrl: getThumbnailImageUrlFromImage(
                          blog?.mainImage?.data?.attributes
                        ),
                      }
                    : {})}
                  imageAlt={blog.title}
                  title={blog.title}
                  description={blog.shortDescription || ''}
                />
              );
            })}
          </RelatedArticles>
        </ErrorBoundary>
      </main>
    </>
  );
};

export default BuildingPage;
