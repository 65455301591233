import { useEffect, useState } from 'react';
import {
  formatPhoneNumber,
  getAgentDataFromLocalStorage,
  getLeadIdFromLocalStorage,
} from '../src/utils/utils';

export function usePhoneNumber({
  unitPrice,
  open,
}: {
  unitPrice?: number;
  open: boolean;
}) {
  const [phoneEnding, setPhoneEnding] = useState('697-7667');
  const [areaCode, setAreaCode] = useState('305');
  const [dialogPhoneEnding, setDialogPhoneEnding] = useState('697-7667');
  const [dialogAreaCode, setDialogAreaCode] = useState('305');

  useEffect(() => {
    const leadId = getLeadIdFromLocalStorage();

    if (leadId) {
      try {
        const agentsStringData = getAgentDataFromLocalStorage() || null;

        if (agentsStringData) {
          const agentsData = JSON.parse(agentsStringData);
          const leadAgentNumber =
            agentsData?.secondAgent?.agentData?.phone ||
            agentsData?.secondAgent?.phone ||
            agentsData?.commonAgent?.agentData?.phone ||
            agentsData?.commonAgent?.phone;

          if (leadAgentNumber) {
            const data = formatPhoneNumber(leadAgentNumber + '');
            if (data.phoneNumber && phoneEnding !== data.phoneNumber) {
              setDialogPhoneEnding(data.phoneNumber);
            }

            if (data.areaCode && areaCode !== data.areaCode) {
              setDialogAreaCode(data.areaCode);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [open]);

  useEffect(() => {
    try {
      if (unitPrice && unitPrice > 1000000) {
        const agentsStringData = getAgentDataFromLocalStorage() || null;

        if (agentsStringData) {
          const agentsData = JSON.parse(agentsStringData);
          console.log(agentsData);
          const higherMillionNumber =
            agentsData?.higherMillion?.agentData?.phone ||
            agentsData?.higherMillion?.phone;

          if (higherMillionNumber) {
            const data = formatPhoneNumber(higherMillionNumber + '');
            if (data.phoneNumber && phoneEnding !== data.phoneNumber) {
              setPhoneEnding(data.phoneNumber);
            }

            if (data.areaCode && areaCode !== data.areaCode) {
              setAreaCode(data.areaCode);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [unitPrice]);

  return {
    phoneEnding,
    dialogPhoneEnding,
    dialogAreaCode,
    areaCode,
  };
}
