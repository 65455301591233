import qs from 'qs';
import { get, isEqual } from 'lodash';

import {
  AnyValue,
  FilterCoordinates,
  FiltersValues,
  PropertyType,
} from '../../UnitsFilters/types';
import { ANY } from '../../../constants/building/availableCondos';
import {
  IntFilterInput,
  ListingFiltersInput,
  StringFilterInput,
} from '../../../apolloClient/types/filters';
import {
  defaultFilterValues,
  SEARCH_RENT_FILTERS,
  SEARCH_SALE_FILTERS,
  sortOptions,
} from '../../UnitsFilters/constants';
import { Listing } from '../../../apolloClient/types/Units';
import { flattenStrapiDataItem } from '../../../lib/flattenStrapiBulkDataItems';
import { Building, Neighborhood } from '../../../apolloClient/types';
import { getYear, subYears } from 'date-fns';
import { getNumberFromString, numberFormatter } from '../../../src/utils/utils';
import { SearchBoxFilters } from 'components/Search/SearchInput/types';
import { generateSearchBoxFilters } from '../../../src/utils/generateSearchboxFilter';
import { getParamsForUrl, LinkFilters } from './searchUtils';
import { getPropertyTypeByCode } from '../../../src/utils/getPropertyTypeByCode';

export function getFilterLabel(
  value: AnyValue,
  defaultValue: string,
  formatter?: (value: string) => string
) {
  const getFormattedValue = (value: string) => {
    return value === ANY ? null : formatter ? formatter(value) : value;
  };

  const min = getFormattedValue(value.min);
  const max = getFormattedValue(value.max);

  if (min && !max) return `${min}+`;
  if (max && !min) return `up to ${max}`;
  if (max === min && max && min) return `${min}`;
  if (max && min) return `${min} - ${max}`;
  return defaultValue;
}

const createRentTermsQuery = (value: string): ListingFiltersInput => {
  if (value.includes('-')) {
    const range = value.split('-').map(Number);
    return {
      unit: { building: { maximumTimesShortTerm: { between: range } } },
    };
  }
  return {
    unit: { building: { maximumTimesShortTerm: { eq: Number(value) } } },
  };
};

export function getMoreFiltersLabel(
  values: { [index: string]: any },
  defaultValues: { [index: string]: any }
): string {
  const skipKeys: (keyof Partial<FiltersValues>)[] = [
    'searchBoxFilters',
    'preConstruction',
    'newConstruction',
    'foreclosures',
    'shortSales',
    'existingCondos',
    'forRent',
    'forSale',
    'sold',
    'sortOption',
  ];

  let activeFiltersCount = 0;
  const excludedValues = ['pcc', 'att'];
  Object.keys(values).forEach((key) => {
    if (skipKeys.includes(key as keyof Partial<FiltersValues>)) return;
    // @TODO: remove this logic and fix the prefix for maxHOA & anyPrice
    if (key === 'maxHOA' && !values[key] && values[key] !== 0) return;
    if (key === 'anyPrice') {
      const priceRange = values[key];
      if (
        priceRange &&
        !priceRange.min &&
        !priceRange.min &&
        (priceRange.min === '' || priceRange.max === '')
      ) {
        return;
      }
    }
    if (!isEqual(values[key], defaultValues[key])) {
      activeFiltersCount += 1;
    }
  });

  const prefix = activeFiltersCount ? `(${activeFiltersCount})` : '';

  return 'More Filters ' + prefix;
}

type QueryCriteriaFields = keyof FiltersValues;

type FilterValueQueryMapperType<T, K extends keyof T> = {
  [key in K]: (
    prevQuery: ListingFiltersInput,
    value: T[K],
    iOnlyWantFilters: ListingFiltersInput[],
    allValues: FiltersValues
  ) => ListingFiltersInput;
};

export const TYPE_SALE = 'sale';
export const TYPE_RENT = 'rent';

const listingTypeMapper = {
  [TYPE_SALE]: SEARCH_SALE_FILTERS,
  [TYPE_RENT]: SEARCH_RENT_FILTERS,
};

export const getSearchLink = (params: Partial<LinkFilters>) => {
  return `/search?${qs.stringify(params, { encodeValuesOnly: true })}`;
};

export function getSearchUrlByType(
  type: PropertyType,
  filters: Partial<FiltersValues>
): string {
  const query = getParamsForUrl(
    {
      ...listingTypeMapper[type],
      ...filters,
    },
    defaultFilterValues
  );
  return getSearchLink(query);
}

export function createSearchUrlByType(
  type: PropertyType,
  filters: Partial<LinkFilters>
): string {
  return getSearchLink({ ...listingTypeMapper[type], ...filters });
}

const createRangeFilter = (value: AnyValue): IntFilterInput => {
  if (value.max === 'Any' && value.min === 'Any') return {};
  if (value.max === 'Any' && !Number.isNaN(Number(value.min))) {
    return { gte: Number(value.min) };
  }
  if (value.min === 'Any' && !Number.isNaN(Number(value.max))) {
    return { lte: Number(value.max) };
  }

  return { between: [Number(value.min), Number(value.max)] };
};

const isEmptyAny = (val: any) =>
  val?.min !== undefined &&
  val?.max !== undefined &&
  val.min === 'Any' &&
  val.max === 'Any';

const getKeywordQuery = (word: string): StringFilterInput => {
  const replaced = word.replace('!', '');
  if (replaced.length !== word.length) {
    return { not: { contains: replaced } };
  }
  return { contains: replaced };
};

export const addAndCriteria = (
  query: ListingFiltersInput,
  filter: ListingFiltersInput
) => {
  if (query.and) {
    query.and.push(filter);
  } else {
    query.and = [filter];
  }
  return query;
};

export const addOrCriteria = (
  query: ListingFiltersInput,
  filter: ListingFiltersInput
) => {
  if (query.or) {
    query.or.push(filter);
  } else {
    query.or = [filter];
  }
  return query;
};

const parseBool = (val: any) => {
  if (val === 'false') return false;
  return Boolean(val);
};

export const filterValueQueryMapper: FilterValueQueryMapperType<
  Partial<FiltersValues>,
  QueryCriteriaFields
> = {
  anyBeds: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      bedsTotal: createRangeFilter(value as AnyValue),
    }),
  anyPrice: (prevQuery, value): ListingFiltersInput => {
    const max = get(value, 'max');
    const min = get(value, 'min');

    if (+min && +max) {
      return Object.assign<ListingFiltersInput, ListingFiltersInput>(
        prevQuery,
        {
          price: createRangeFilter(value as AnyValue),
        }
      );
    } else if (!+max && +min) {
      return Object.assign<ListingFiltersInput, ListingFiltersInput>(
        prevQuery,
        {
          price: { gte: +min },
        }
      );
    } else if (!+min && +max) {
      return Object.assign<ListingFiltersInput, ListingFiltersInput>(
        prevQuery,
        {
          price: { lte: +max },
        }
      );
    }

    return prevQuery;
  },
  oceanView: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        view: {
          ocean: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  skylineView: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        view: {
          skyline: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  oceanfront: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        waterfront: {
          ocean: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  beachfront: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        waterfront: {
          beach: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  wfAny: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        waterfront: {
          any: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  intracoastalView: (
    prevQuery,
    value,
    iOnlyWantFilters
  ): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        view: {
          or: [
            { intracoastal: { eq: parseBool(value) } },
            { bay: { eq: parseBool(value) } },
          ],
        },
      },
    });
    return prevQuery;
  },
  sqft: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      sqft: createRangeFilter(value as AnyValue),
    }),
  baths: (prevQuery, value): ListingFiltersInput => {
    let query: ListingFiltersInput = {
      bathsFull: { gte: parseInt(value as string) },
    };
    if (
      parseFloat(value as string) === 2.5 ||
      parseFloat(value as string) === 1.5
    ) {
      query = {
        or: [
          {
            and: [
              { bathsHalf: { eq: 1 } },
              { bathsFull: { gte: parseInt(value as string) } },
            ],
          },
          { bathsFull: { gte: parseInt(value as string) + 1 } },
        ],
      };
    }
    return addAndCriteria(prevQuery, query);
  },
  parkingPlaces: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      amenity: {
        ...(prevQuery?.amenity || {}),
        parking: {
          ...(prevQuery?.amenity?.parking || {}),
          places: { gte: Number.parseInt(value as string) },
        },
      },
    }),
  furnished: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        convenience: {
          furnished: { eq: parseBool(value) },
        },
      },
    });
    return prevQuery;
  },
  unfurnished: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      amenity: {
        convenience: {
          or: [
            { unfurnished: { eq: parseBool(value) } },
            {
              unfurnished: { eq: false },
              furnished: { eq: false },
            },
          ],
        },
      },
    });
    return prevQuery;
  },
  yearBuild: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      yearBuilt: createRangeFilter(value as AnyValue),
    }),
  bitcoin: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      containsCrypto: { eq: parseBool(value) },
    }),
  sellerFinancing: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      containsSellerFinancing: { eq: parseBool(value) },
    }),
  corner: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      containsCorner: { eq: parseBool(value) },
    }),
  floor: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      unitFloorLocation: createRangeFilter(value as AnyValue),
    }),
  priceSqFt: (prevQuery, value): ListingFiltersInput =>
    Object.assign<ListingFiltersInput, ListingFiltersInput>(prevQuery, {
      priceSqft: createRangeFilter(value as AnyValue),
    }),
  unitsInBuilding: (prevQuery, value): ListingFiltersInput =>
    addAndCriteria(prevQuery, {
      unit: {
        building: {
          unitsCount: createRangeFilter(value as AnyValue),
        },
      },
    }),
  preConstruction: (prevQuery): ListingFiltersInput => {
    const baseQuery = [
      { statusCode: { eq: 'A' } },
      { propertyTypeCode: { ne: 'R' } },
      {
        or: [{ isReo: { eq: false } }, { isReo: { eq: null } }],
      },
      {
        or: [{ isShortSale: { eq: false } }, { isShortSale: { eq: null } }],
      },
    ];
    addOrCriteria(prevQuery, {
      and: [
        ...baseQuery,
        { unit: { building: { isPreconstruction: { eq: true } } } },
      ],
    });
    addOrCriteria(prevQuery, {
      and: [...baseQuery, { yearBuilt: { gte: new Date().getFullYear() + 1 } }],
    });
    return prevQuery;
  },
  newConstruction: (prevQuery): ListingFiltersInput => {
    addOrCriteria(prevQuery, {
      and: [
        { statusCode: { eq: 'A' } },
        { propertyTypeCode: { ne: 'R' } },
        {
          or: [{ isReo: { eq: false } }, { isReo: { eq: null } }],
        },
        {
          or: [{ isShortSale: { eq: false } }, { isShortSale: { eq: null } }],
        },
        {
          unit: {
            building: {
              or: [
                { isPreconstruction: { eq: false } },
                { isPreconstruction: { eq: null } },
              ],
            },
          },
        },
        {
          yearBuilt: {
            gte: getYear(subYears(new Date(), 2)),
            lte: getYear(new Date()),
          },
        },
      ],
    });
    return prevQuery;
  },
  foreclosures: (prevQuery): ListingFiltersInput => {
    addOrCriteria(prevQuery, {
      and: [
        { statusCode: { eq: 'A' } },
        { propertyTypeCode: { ne: 'R' } },
        { isReo: { eq: true } },
        {
          or: [{ isShortSale: { eq: false } }, { isShortSale: { eq: null } }],
        },
      ],
    });
    return prevQuery;
  },
  shortSales: (prevQuery): ListingFiltersInput => {
    addOrCriteria(prevQuery, {
      and: [
        { statusCode: { eq: 'A' } },
        { propertyTypeCode: { ne: 'R' } },
        { isShortSale: { eq: true } },
        {
          or: [{ isReo: { eq: false } }, { isReo: { eq: null } }],
        },
      ],
    });
    return prevQuery;
  },
  existingCondos: (prevQuery: ListingFiltersInput): ListingFiltersInput => {
    return addOrCriteria(prevQuery, {
      and: [
        { statusCode: { eq: 'A' } },
        { propertyTypeCode: { ne: 'R' } },
        {
          unit: {
            building: {
              or: [
                { isPreconstruction: { eq: false } },
                { isPreconstruction: { eq: null } },
              ],
            },
          },
        },
        {
          or: [{ isReo: { eq: false } }, { isReo: { eq: null } }],
        },
        {
          or: [{ isShortSale: { eq: false } }, { isShortSale: { eq: null } }],
        },
      ],
    });
  },
  forRent: (prevQuery: ListingFiltersInput): ListingFiltersInput => {
    return addOrCriteria(prevQuery, {
      and: [{ propertyTypeCode: { eq: 'R' } }, { statusCode: { eq: 'A' } }],
    });
  },
  sold: (prevQuery: ListingFiltersInput): ListingFiltersInput => {
    return addOrCriteria(prevQuery, {
      statusCode: { eq: 'CS' },
    });
  },
  penthouse: (prevQuery, value, iOnlyWantFilters): ListingFiltersInput => {
    iOnlyWantFilters?.push({
      containsPenthouse: { eq: parseBool(value) },
    });
    return prevQuery;
  },
  showPendingListings: (prevQuery: ListingFiltersInput): ListingFiltersInput =>
    addOrCriteria(prevQuery, { statusCode: { in: ['AC', 'PS', 'B'] } }),
  maxHOA: (prevQuery: ListingFiltersInput, value): ListingFiltersInput =>
    addAndCriteria(prevQuery, {
      or: [
        { hoaFees: { lte: Number(value), gte: Number(1) } },
        {
          and: [
            {
              or: [{ hoaFees: { null: true } }, { hoaFees: { lt: Number(1) } }],
            },
            {
              maintenanceFee: {
                lte: Number(value),
                gte: Number(1),
                null: false,
              },
            },
          ],
        },
      ],
    }),
  daysOnCbb: (prevQuery, value) => {
    const date = Date.now();
    const mSecondsAgo = Number(value) * 86400000;
    return Object.assign(prevQuery, {
      createdAt: { gte: new Date(date - mSecondsAgo).toISOString() },
    });
  },
  closedAt: (prevQuery, value) => {
    const date = Date.now();
    const mSecondsAgo = Number(value);
    return addOrCriteria(prevQuery, {
      and: [
        { statusCode: { eq: 'CS' } },
        { closeDate: { gte: new Date(date - mSecondsAgo).toISOString() } },
      ],
    });
    // return Object.assign(prevQuery, {
    //   closeDate: { gte: new Date(date - mSecondsAgo).toISOString() },
    // });
  },
  unitNumberRegex: (prevQuery, value) => {
    let includeCriteria: Object[] = [];
    let excludeCriteria: Object[] = [];

    (value as string).split(',').forEach((item) => {
      const trimNumber = item.trim();
      const number = trimNumber.replace(/\D+/g, '');
      if (trimNumber.startsWith('!') && trimNumber.endsWith('*')) {
        excludeCriteria.push({ not: { unitNumber: { startsWith: number } } });
      } else if (trimNumber.startsWith('!*')) {
        excludeCriteria.push({ not: { unitNumber: { endsWith: number } } });
      } else if (trimNumber.startsWith('*')) {
        includeCriteria.push({ unitNumber: { endsWith: number } });
      } else if (trimNumber.endsWith('*')) {
        includeCriteria.push({ unitNumber: { startsWith: number } });
      } else {
        includeCriteria.push({ unitNumber: { endsWith: number } });
      }
    });

    if (includeCriteria.length > 0) {
      addAndCriteria(prevQuery, { or: includeCriteria });
    }

    excludeCriteria.forEach((criteria) => {
      addAndCriteria(prevQuery, criteria);
    });

    return prevQuery;
  },
  keyWords: (prevQuery, value) => {
    let newQuery = prevQuery;
    (value as string)
      .split(',')
      .filter((chunk) => chunk)
      .forEach((chunk) => {
        const chunkArr = chunk.split(' ').filter((word) => word);

        if (chunkArr.length === 1) {
          newQuery = addAndCriteria(prevQuery, {
            keywords: getKeywordQuery(chunkArr[0]),
          });
        } else {
          const or: ListingFiltersInput[] = [];

          chunkArr.forEach((word) => {
            or.push({ keywords: getKeywordQuery(word) });
          });

          newQuery = addAndCriteria(prevQuery, { or });
        }
      });

    return newQuery;
  },
  shortTermRent: (prevQuery): ListingFiltersInput =>
    addAndCriteria(prevQuery, {
      unit: { building: { allowsShortTerm: { eq: true } } },
    }),
  searchBoxFilters: (prevQuery, value) => {
    const newCriteria = generateSearchBoxFilters(value as SearchBoxFilters);

    if (newCriteria) {
      return addAndCriteria(prevQuery, newCriteria);
    }

    return prevQuery;
  },
  rentalInvestments: (prevQuery) => {
    const sixMonthAgo = new Date();
    sixMonthAgo.setMonth(sixMonthAgo.getMonth() - 6);
    return addAndCriteria(prevQuery, {
      unit: {
        latestRentListing: {
          closeDate: { gte: sixMonthAgo.toISOString() },
        },
      },
    });
  },
  shortTermRentTerms: (prevQuery, value, _, allValues) => {
    if (Array.isArray(value)) {
      if (value.includes(ANY)) return prevQuery;
      allValues.shortTermRent &&
        addAndCriteria(prevQuery, {
          or: value.map((_) => createRentTermsQuery(_ as string)),
        });
    }
    return prevQuery;
  },
  secretSearch: (prevQuery, value) => {
    const chunks = (value as string)
      .match(/\w+|"[^"]+"/g)
      ?.filter((chunk) => chunk);
    if (chunks) {
      const { negative, positive } = chunks.reduce<{
        positive: string[];
        negative: string[];
      }>(
        ({ positive, negative }, item) => {
          if (item.startsWith('!')) {
            negative.push(item.replace('!', ''));
          } else {
            positive.push(item);
          }
          return { negative, positive };
        },
        { positive: [], negative: [] }
      );

      const positiveCondition = positive.length
        ? positive.map((item) => ({
            remarks: { containsi: item.replaceAll('"', '') },
          }))
        : undefined;
      const negativeCondition = negative.length
        ? negative.map((item) => ({
            not: { remarks: { containsi: item.replaceAll('"', '') } },
          }))
        : undefined;

      return addAndCriteria(prevQuery, {
        or: positiveCondition,
        and: negativeCondition,
      });
    }
    return prevQuery;
  },
  coordinates: (prevQuery, _): ListingFiltersInput => {
    const { southWest, northEast } = _ as FilterCoordinates;
    return addAndCriteria(prevQuery, {
      and: [
        {
          unit: {
            building: {
              lon: { gte: Number(southWest.lng) },
              lat: { gte: Number(southWest.lat) },
            },
          },
        },
        {
          unit: {
            building: {
              lon: { lte: Number(northEast.lng) },
              lat: { lte: Number(northEast.lat) },
            },
          },
        },
      ],
    });
  },
};

const petsFriendlyQueries = {
  sale: {
    and: [
      { propertyTypeCode: { ne: 'R' } },
      {
        or: [
          { isPetsAllowed: { eq: true } },
          {
            and: [
              { isPetsAllowed: { null: true } },
              {
                unit: {
                  building: {
                    amenity: { pets: { allowedForRent: { eq: true } } },
                  },
                },
              },
            ],
          },
          { amenity: { pets: { large: { eq: true } } } },
          {
            and: [
              { amenity: { pets: { large: { null: true } } } },
              {
                unit: {
                  building: {
                    amenity: { pets: { largeForRent: { eq: true } } },
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  },
  rent: {
    and: [
      {
        propertyTypeCode: { eq: 'R' },
      },
      {
        or: [
          { isPetsAllowed: { eq: true } },
          { amenity: { pets: { large: { eq: true } } } },
        ],
      },
    ],
  },
};

export function getQueryObjectFromValues(
  baseQuery: ListingFiltersInput,
  values: FiltersValues,
  propertyType?: PropertyType
): ListingFiltersInput {
  const { petFriendly } = values;

  let queryFilters: ListingFiltersInput = baseQuery;
  let iOnlyWantFilters: ListingFiltersInput[] = [];

  (Object.keys(values) as Array<keyof FiltersValues>).forEach((key) => {
    if (
      values[key] &&
      values[key] !== 'Any' &&
      values[key] !== '0' &&
      !isEmptyAny(values[key])
    ) {
      queryFilters =
        filterValueQueryMapper[key]?.(
          queryFilters,
          values[key],
          iOnlyWantFilters,
          values
        ) || queryFilters;
    }
  });

  if (petFriendly) {
    if (propertyType) {
      iOnlyWantFilters.push(
        propertyType === 'rent'
          ? petsFriendlyQueries.rent
          : petsFriendlyQueries.sale
      );
    } else {
      iOnlyWantFilters.push(petsFriendlyQueries.rent);
      iOnlyWantFilters.push(petsFriendlyQueries.sale);
    }
  }

  if (iOnlyWantFilters.length > 0) {
    addAndCriteria(queryFilters, { or: iOnlyWantFilters });
  }

  return queryFilters;
}

const sorts: { [index: string]: Array<string> } = {
  [sortOptions.RECOMMENDED]: ['isRecommended:desc'],
  [sortOptions.PRICE_HIGHEST_FIRST]: ['price:desc'],
  [sortOptions.PRICE_LOWEST_FIRST]: ['price:asc'],
  [sortOptions.SQUARE_FEET]: ['hasSqft:desc', 'sqft:desc'],
  [sortOptions.BEDROOMS]: ['bedsTotal:desc'],
  [sortOptions.RECENT_CHANGES]: ['updatedAt:desc'],
};

export const getSortOption = (option: string) => {
  return sorts[option];
};

export function getHrefForUnitLink(listing: Listing) {
  const unit = flattenStrapiDataItem(listing?.unit?.data);
  const nhCode = flattenStrapiDataItem(
    flattenStrapiDataItem(unit.building?.data)?.neighborhood?.data
  )?.slug;
  const buildingUrl = flattenStrapiDataItem(unit.building?.data)?.slug;
  return `/${nhCode}/${buildingUrl}/unit-${encodeURIComponent(
    unit.unique
  )}-for-${getPropertyTypeByCode(listing.propertyTypeCode)}`;
}

export function getHrefForBuildingLink({
  neighborhood,
  building,
}: {
  neighborhood: Neighborhood;
  building: Building;
}) {
  const nhCode = neighborhood.slug;
  const buildingUrl = building.slug;
  return `/${nhCode}/${buildingUrl}`;
}

export function normalizePriceValue(value: string): string {
  const numbersFromValue = getNumberFromString(value);
  return numbersFromValue ? numberFormatter(numbersFromValue) : '';
}

export function formatChangedPriceValue(value: string): string {
  return getNumberFromString(normalizePriceValue(value)).toString();
}

export function compareDefaultValues(
  values: { [index: string]: any },
  defaultValues: { [index: string]: any }
): {
  [index: string]: any;
} {
  const discrepancies: { [index: string]: any } = {} as {
    [index: string]: any;
  };

  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      if (!isEqual(defaultValues[key], values[key])) {
        if (key === 'searchBoxFilters') {
        }
        discrepancies[key] = values[key];
      }
    }
  }

  return discrepancies;
}
