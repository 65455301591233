import { useLazyQuery } from '@apollo/client';
import { client } from 'apolloClient/client';
import { cloneDeep } from 'lodash';
import { SEARCH_DATA } from 'apolloClient/queries/searchListings';
import { ListingFiltersInput } from 'apolloClient/types/filters';
import { SearchPageResponse } from 'apolloClient/types/Searches';
import { FiltersValues, PropertyType } from 'components/UnitsFilters/types';
import { flattenStrapiBulkDataItems } from 'lib/flattenStrapiBulkDataItems';
import {
  addAndCriteria,
  compareDefaultValues,
} from '../components/Search/utils/listingsFiltersUtils';
import { Building } from '../apolloClient/types';
import { useAuth } from 'components/Auth/AuthProvider';
import { useFavorite } from 'components/SavedHouses/SavedFavoritesContext';
import { useEffect, useMemo, useState } from 'react';
import { updateSearchgFavoritesQuery } from 'src/utils/updateListingFavoritesQuery';
import { isEqualValues } from 'src/utils/utils';
import {
  initialFilters,
  initialFiltersStatuses,
  initialFiltersEmptyListingsType,
  isListingTypesEmpty,
} from '../constants/initialSearchFilter';
import {
  defaultSortOption,
  defaultFilterValues,
} from 'components/UnitsFilters/constants';
import { transformSearchPageListings } from 'src/utils/searchPageDataTransformer';
import { useRouter } from 'next/router';

export const getPropTypeQuery = (
  propType: PropertyType
): ListingFiltersInput => {
  if (propType === 'rent') return { rentUnit: { id: { notNull: true } } };
  return { saleUnit: { id: { notNull: true } } };
};

export const createBaseQuery = (
  buildingId?: number,
  propertyType?: PropertyType,
  values?: FiltersValues
) => {
  let filters: ListingFiltersInput = {};
  if (buildingId) {
    filters = Object.assign<ListingFiltersInput, ListingFiltersInput>(filters, {
      and: [
        { unit: { building: { id: { eq: Number(buildingId) } } } },
        {
          or: [
            { saleUnit: { id: { notNull: true } } },
            { rentUnit: { id: { notNull: true } } },
          ],
        },
      ],
    });
  } else {
    filters = Object.assign<ListingFiltersInput, ListingFiltersInput>(
      filters,
      cloneDeep(initialFilters)
    );
    if (isListingTypesEmpty(values)) {
      addAndCriteria(filters, initialFiltersEmptyListingsType);
    } else {
      addAndCriteria(filters, initialFiltersStatuses);
    }
  }
  if (propertyType) {
    filters = Object.assign<ListingFiltersInput, ListingFiltersInput>(
      filters,
      getPropTypeQuery(propertyType)
    );
  }
  return filters;
};

const updateSearchCache = (
  prevQueryResult: SearchPageResponse,
  options: { fetchMoreResult: SearchPageResponse }
): SearchPageResponse => {
  return {
    ...prevQueryResult,
    ...options.fetchMoreResult,
  };
};

export const useSearch = ({
  buildingId,
  pageSize,
  searchValues,
}: {
  buildingId?: number;
  pageSize?: number;
  searchValues?: { [index: string]: any };
}) => {
  const router = useRouter();
  const { getActualAuthData, me } = useAuth();

  const { latestAction } = useFavorite();

  const [buildingsData, setBuildingsData] = useState<Building[]>(
    [] as Building[]
  );

  const [
    refetchSearchData,
    {
      data: searchData,
      loading: searchLoading,
      variables,
      updateQuery,
      fetchMore,
    },
  ] = useLazyQuery<
    SearchPageResponse,
    {
      filters: { [index: string]: any };
      pageSize: number;
      page: number;
      loadBuildings: boolean;
      buildingId: number;
    }
  >(SEARCH_DATA, {
    fetchPolicy: 'network-only',
    client,
    variables: {
      filters: searchValues || {},
      pageSize: pageSize || 20,
      page: 0,
      loadBuildings: true,
      buildingId: buildingId || -1,
    },
  });

  const [prevFilters, setPrevFilters] = useState({});

  useEffect(() => {
    if (latestAction) {
      updateQuery(
        updateSearchgFavoritesQuery(latestAction, 'getSearchPageData')
      );
    }
  }, [latestAction]);

  const refetchQueryResults = ({
    values,
    loadBuildings,
    sortOption,
    propertyType,
    page,
  }: {
    values: { [index: string]: any };
    loadBuildings?: boolean;
    sortOption?: string;
    propertyType?: PropertyType;
    page?: number;
  }) => {
    const isFiltersChanged = !isEqualValues(prevFilters, values);
    const { user } = getActualAuthData();

    const filters = compareDefaultValues(values, defaultFilterValues);

    const parameters = {
      filters: {
        ...filters,
        sortOption: sortOption || defaultSortOption,
      },
      pageSize: pageSize || 20,
      page: page || 0,
      authorized: !!user.id || false,
      userId: user.id || -1,
      buildingId: buildingId || -1,
      loadBuildings: loadBuildings || false,
    };
    if (isFiltersChanged) {
      setPrevFilters(values);

      refetchSearchData({
        variables: {
          ...parameters,
        },
      });

      if (loadBuildings && searchData?.getSearchPageData?.buildings) {
        setBuildingsData([]);
      }
    } else {
      fetchMore({
        variables: {
          ...parameters,
          loadBuildings: false,
        },
        updateQuery: updateSearchCache,
      });
    }

    router.push({
      pathname: router.pathname,
      query: { ...router.query, page: page || 0 },
    }, undefined, { scroll: false });
  };

  const transformedListings = useMemo(() => {
    const listingsData = searchData?.getSearchPageData?.listings?.data;
    if (listingsData) {
      return listingsData.map((listing) =>
        transformSearchPageListings(listing)
      );
    }
  }, [searchData?.getSearchPageData?.listings]);
  const listings = flattenStrapiBulkDataItems(transformedListings);
  const pagination = searchData?.getSearchPageData?.listings?.meta?.pagination;

  const paginate = (page: number, sortOption?: string) => {
    if (variables) {
      fetchMore({
        variables: {
          ...variables,
          filters: {
            ...variables?.filters,
            sortOption: sortOption || defaultSortOption,
          },

          page,
          loadBuildings: false,
        },
        updateQuery: updateSearchCache,
      });
    }
  };

  useEffect(() => {
    if (!buildingsData.length && searchData) {
      setBuildingsData(searchData?.getSearchPageData?.buildings);
    }
  }, [searchData]);

  // eslint-disable-next-line max-len
  const buildings = useMemo(
    () => (buildingsData?.length ? buildingsData : []),
    [buildingsData]
  );

  return {
    listings,
    refetchQueryResults,
    pagination,
    variables,
    paginate,
    buildings,
    buildingsLoading: searchLoading,
    listingsLoading: searchLoading,
  };
};
