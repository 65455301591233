import React, { useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import CondoCard from './CondoCard';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';

import { Listing } from 'apolloClient/types/Units';
import { Pagination } from 'apolloClient/types/common';

import arrowButton from '../../../public/images/arrow-button.svg';
import { EntityWithId } from '../../../apolloClient/types';
import { priceFormatter } from '../../../src/utils/utils';
import { getHrefForUnitLink } from '../utils/listingsFiltersUtils';

import { Table } from '../../Table';
import { FiltersValues } from 'components/UnitsFilters/types';

type CondosSearchResultProps = {
  gridResults: string;
  imageClassName?: string;
  paginateClassName?: string;
  items?: EntityWithId<Listing>[];
  resultDisplaying?: 'table' | 'cards';
  pagination?: Pagination;
  sortOption?: string;
  setCurrentPage?: (val: number, sortOption?: string) => void;
  showTreeDots?: boolean;
  isPPC?: boolean;
  isIconPPC?: boolean;
  setFiltersValues?: (values: FiltersValues) => void;
};

const CondosSearchResult: React.FC<CondosSearchResultProps> = ({
  isIconPPC,
  isPPC,
  gridResults,
  imageClassName,
  paginateClassName,
  items = [],
  resultDisplaying = 'cards',
  pagination,
  sortOption,
  setCurrentPage,
  showTreeDots = false,
  setFiltersValues,
}) => {
  const router = useRouter();

  const saveScrollPosition = (): void => {
    localStorage?.setItem('searchScrollPosition', window.scrollY.toString());
  };

  const saveAsPath = (str: string): void => {
    localStorage.setItem('asPathForSearchPage', str);
  };

  const handlePageClick = (event: { selected: number }) => {
    const newPage = event.selected + 1;
    if (pagination?.page !== newPage && setCurrentPage) {
      saveAsPath(router.asPath);
      setCurrentPage(newPage, sortOption);
      scrollTo({ top: 0, behavior: 'smooth' });

      router.push({
        pathname: router.pathname,
        query: { ...router.query, page: newPage },
      }, undefined, { scroll: false });
    }
  };

  function getDataForTable(data: EntityWithId<Listing>[] | null) {
    if (data === null) return [];
    return data.map((item) => {
      return {
        id: item.id,
        unit: item.unitNumber,
        bedBath: `${item.bedsTotal || 0}/${item.bathsFull}`,
        sqft: item.sqft,
        price: item.price,
        href: getHrefForUnitLink(item),
      };
    });
  }

  const Pagination = useCallback(() => {
    return (
      <ReactPaginate
        nextLabel={
          <div className="flex justify-between w-[62px] md:w-full gap-[5px]">
            <div className="leading-4 uppercase">Next</div>
            <div className="flex w-5">
              <Image src={arrowButton} alt="next" />
            </div>
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        marginPagesDisplayed={1}
        pageCount={pagination?.pageCount || 1}
        previousLabel={
          <div className="flex justify-between w-[62px] md:w-full gap-[5px]">
            <div className="flex w-5 transform rotate-180">
              <Image src={arrowButton} alt="previous" />
            </div>
            <div className="leading-4 uppercase">Prev</div>
          </div>
        }
        pageLinkClassName="outline-none flex w-10 h-10 border border-gold items-center justify-center"
        previousLinkClassName="outline-none flex border w-max md:w-20 lg:w-32 xl:w-[110px] h-10 border-gold px-2 lg:px-4  items-center justify-center"
        nextLinkClassName="outline-none flex border w-max md:w-20 lg:w-32 xl:w-[110px] h-10 border-gold px-2 lg:px-4 items-center justify-center"
        breakLabel="..."
        breakLinkClassName="outline-none flex w-10 h-10 border border-gold items-center justify-center"
        containerClassName="flex text-14-26-0.3 justify-center md:justify-start w-auto items-center mt-[1px]"
        activeClassName="active bg-gold outline-none"
        initialPage={(pagination?.page || 1) - 1 || 0}
      />
    );
  }, [items, pagination]);

  return (
    <div onClick={saveScrollPosition}>
      {resultDisplaying === 'cards' && (
        <div
          className={`grid gap-[13px] md:gap-[30px] lg:gap-[16px] xl:gap-y-[24px] px-0 mx-[20px] md:mx-0 xl:mx-0 lg:mx-0 lg:my-4 lg:mt-[12px] mb-[40px] lg:mb-[75px] lg:gap-x-[42px] mt-[14px] md:mt-[16px] ${gridResults}`}
        >
          {items &&
            items.map((listing, index) => {
              /**
               * @todo check if ok
               */
              return (
                <CondoCard
                  isIconPPC={isIconPPC}
                  isPPC={isPPC}
                  showThreeDots={showTreeDots}
                  key={index}
                  index={index}
                  items={items}
                  listing={listing}
                  smallHeight={false}
                  imageClassName={imageClassName}
                  setFiltersValues={setFiltersValues}
                />
              );
            })}
        </div>
      )}
      {resultDisplaying === 'table' && (
        <div className="mb-10">
          <Table
            rowClasses="font-sohneKraftig text-16-26-0.3 cursor-pointer"
            onRowClick={(row) => router.push(row.href as string)}
            columns={[
              {
                key: 'unit',
                headingMobile: 'UNIT',
              },
              {
                key: 'bedBath',
                heading: 'BED/BATH',
                headingMobile: 'BED/BATH',
              },
              {
                key: 'sqft',
                heading: 'SQ FT',
                headingMobile: 'SQ FT',
              },
              {
                key: 'price',
                heading: 'SALE PRICE',
                headingMobile: 'SALE PRICE',
                cellRender: (data) => (
                  <div>
                    <span>{priceFormatter(data.price)}</span>
                  </div>
                ),
              },
              {
                key: 'pricePerFt',
                heading: 'PRICE / SQ FT',
                headingMobile: 'PRICE / SQ FT',
                cellRender: (data) => (
                  <div>
                    <span>{priceFormatter(data.price / data.sqft)}</span>
                  </div>
                ),
              },
            ]}
            data={getDataForTable(items)}
          />
        </div>
      )}
      {(pagination?.pageCount || 0) > 1 && (
        <div className={`flex justify-center ${paginateClassName}`}>
          <Pagination />
        </div>
      )}
    </div>
  );
};

export default React.memo(CondosSearchResult, (prevProps, nextProps) => {
  const gridResults = prevProps.gridResults !== nextProps.gridResults;
  const loggedNotChanged = prevProps.showTreeDots !== nextProps.showTreeDots;
  const existingUnitKeys = new Set(
    prevProps.items?.map((listing, index) => `${listing.id}_${index}`)
  );
  const newUnitKeys = new Set(
    nextProps.items?.map((listing, index) => `${listing.id}_${index}`)
  );

  const isNotFavoritesMatch = prevProps.items?.find(
    (listing, index) =>
      nextProps.items?.[index]?.unit?.data?.attributes?.favorite?.data
        .length !== listing?.unit?.data?.attributes?.favorite?.data.length
  );

  let newMatchToOld: boolean = true;
  for (let key of Array.from(newUnitKeys)) {
    if (!existingUnitKeys.has(key)) {
      newMatchToOld = false;
      break;
    }
  }
  let oldMatchToNew: boolean = true;
  for (let key of Array.from(existingUnitKeys)) {
    if (!newUnitKeys.has(key)) {
      oldMatchToNew = false;
      break;
    }
  }

  return (
    !gridResults &&
    !loggedNotChanged &&
    oldMatchToNew &&
    newMatchToOld &&
    !isNotFavoritesMatch &&
    prevProps.resultDisplaying === nextProps.resultDisplaying
  );
});
