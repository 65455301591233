import { format } from 'date-fns';

export function translateUnitStatus(
  propertyTypeCode: string,
  statusCode: string,
  closeDate: string | null | undefined,
  isReo?: boolean,
  isShortSale?: boolean,
  isPreconstruction?: boolean,
  yearBuilt?: number
): string {
  const currentYear = new Date().getFullYear();
  const isPreconstructionData =
    isPreconstruction || Number(yearBuilt) > currentYear;

  if (isReo && statusCode !== 'CS' && !isPreconstructionData) {
    return `Foreclosure`;
  } else if (isShortSale && statusCode !== 'CS' && !isPreconstructionData) {
    return `Short sale`;
  } else if (isPreconstructionData && statusCode !== 'CS') {
    return `Pre-construction`;
  } else if (
    (propertyTypeCode !== 'R' && statusCode === 'A') ||
    (propertyTypeCode !== 'R' && statusCode === 'AC')
  ) {
    return `For sale`;
  } else if (
    (propertyTypeCode === 'R' && statusCode === 'A') ||
    (propertyTypeCode === 'R' && statusCode === 'AC')
  ) {
    return `For rent`;
  } else if (
    statusCode === 'PS' ||
    statusCode === 'C' ||
    statusCode === 'X' ||
    statusCode === 'T' ||
    statusCode === 'W' ||
    statusCode === 'Q'
  ) {
    return `Off market`;
  } else if (statusCode === 'R') {
    return `Rented ${
      closeDate ? format(new Date(closeDate), 'MM-dd-yyyy') : ''
    }`;
  } else if (statusCode === 'CS') {
    return `Sold ${closeDate ? format(new Date(closeDate), 'MM-dd-yyyy') : ''}`;
  } else {
    return '';
  }
}
